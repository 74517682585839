import {CreateNotificationMutation, Notification, UpdateNotificationByIdMutation} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID, ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL} from '@app/utils/constants';
import {useTranslation} from 'react-i18next';
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter';
import {useMutation, FetchResult} from '@apollo/client';
import Separator from '@app/components/Common/Separator';
import {NOTIFICATIONS_UPDATE_BY_ID, NOTIFICATIONS_CREATE} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import useNotificationStore from '@app/stores/notification';
import {ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import {RadBadge, RadButton} from '@holis/react-ui/rad';
import {useNavigate} from 'react-router-dom';
import {LuCheckCircle, LuRocket} from 'react-icons/lu';
type TFooter = Readonly<{
    notification: Partial<Notification>;
}>

export default function Footer({notification}: TFooter) {
  const {t} = useTranslation();
  const {activeNotification, updateNotificationData, updateNotificationState, hasError} = useNotificationStore();
  const {status} = activeNotification ?? {};
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateNotificationByIdApi] = useMutation<UpdateNotificationByIdMutation>(NOTIFICATIONS_UPDATE_BY_ID);
  const [createNotificationApi] = useMutation<CreateNotificationMutation>(NOTIFICATIONS_CREATE);
  const navigate = useNavigate();
  const generateIan = () => {
    startLoading();
    const actionDate = new Date();
    createNotificationApi({variables: {data: updateNotificationData}}).then((queryResult: FetchResult<CreateNotificationMutation>) => {
      updateNotificationState(queryResult.data?.createOneNotification as Partial<Notification>, actionDate, true);
      AppNotifications.success(t('message.success.addNotification'));
      navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(/:number/, (queryResult.data?.createOneNotification as Partial<Notification>).notif!));
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const approveIan = () => {
    startLoading();
    const actionDate = new Date();
    updateNotificationByIdApi({variables: {id: notification.id, data: {status: {set: ENOTIFICATION_NOTIF_STATUS.APPR.toString()}}}}).then((queryResult: FetchResult<UpdateNotificationByIdMutation>) => {
      updateNotificationState(queryResult.data?.updateOneNotification as Partial<Notification>, actionDate, false);
      AppNotifications.success(t('message.success.notificationUpdated'));
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  return (
    <DetailsPageFooter
      actionButtons={
        <>
          {status === ENOTIFICATION_NOTIF_STATUS.INIT && activeNotification?.id === OBJ_NEW_ID && <RadButton className='bg-primary gap-2' disabled={hasError()} onClick={generateIan}><LuRocket/> {t('label.generateIan')}</RadButton>}
          {status === ENOTIFICATION_NOTIF_STATUS.WAPP && <RadButton className='bg-primary gap-2' onClick={approveIan}><LuCheckCircle size={14}/> {t('label.approve')}</RadButton>}
        </>
      }
    >
      {status && <RadBadge variant='outline' className='bg-indigo-200 text-indigo-700 py-1 font-normal'>{t(`label.notifStatuses.${status.toLowerCase()}`)}</RadBadge>}
      <Separator/>
      <span className='text-black'>
        ID: #{notification.id}
      </span>
    </DetailsPageFooter>
  );
}
