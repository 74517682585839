import {gql} from '@apollo/client';
import {FLOC_REF_PARTS_COMMON_FIELDS} from './refFlocParts';
import {GRIDS_AUTOCOMPLETE_COMMON_FIELDS} from './grids';
import {DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS} from './refDamageCodeGroups';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS} from './flocs';
import {FLOC_CATALOGS_COMMON_FIELDS} from './refFlocCatalogs';
import {NOTIFICATIONS_COMMON_FIELDS} from './notifications';
import {WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS} from './refEventTechniques';

export const DAMAGES_COMMON_FIELDS = gql`
    ${FLOC_REF_PARTS_COMMON_FIELDS}
    ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
    ${DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS}
    ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
    ${FLOC_CATALOGS_COMMON_FIELDS}
    ${NOTIFICATIONS_COMMON_FIELDS}
    ${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
    fragment DamageCommonFields on Damage {
        id
        position
        longDescription
        markerCoordinates2d
        markerCoordinates3d
        description
        flagStatus
        display2d
        display3d
        coordinates3d
        coordinates2d
        idwgId
        partId
        techniqueId
        technique {
            ...RefEventTechniqueCommonFields
        }
        part {
            ...RefFlocPartCommonFields
        }
        gridId
        grid {
            ...GridAutocompleteCommonFields
        }
        codeGroupId
        codeGroup {
            ...RefDamageCodeGroupCommonFields
        }
        flocId
        functionalLocation {
            ...FlocAutocompleteCommonFields
        }
        accessibilityId
        accessibility {
            ...RefFlocCatalogsCommonFields
        }
        maxEndDate
        notificationDamages {
            notification {
                ...NotificationCommonFields
            }
            notifId
        }
        annotation
    }
`;
