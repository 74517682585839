import {useLazyQuery} from '@apollo/client';
import DamageModal from '@app/components/Common/Block/Damage/DamageModal';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {Damage, GetAllDamagesQuery} from '@app/graphql/__types__/graphql';
import {DAMAGES_GET_ALL} from '@app/graphql/requests';
import {useDamagesStore} from '@app/stores/anomalyManagement/damage';
import useDamageStore from '@app/stores/damage';
import {RadButton} from '@holis/react-ui/rad';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MdOpenInNew} from 'react-icons/md';

export default function AnomalyManagementDamagePage() {
  const {setDamages, setFetchDamages, damages, fetchDamages} = useDamagesStore();
  const {setActiveDamage, activeDamage} = useDamageStore();
  const {t} = useTranslation();
  const [getDamagesApi, {loading, error, refetch, called}] = useLazyQuery<GetAllDamagesQuery>(DAMAGES_GET_ALL, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const getDamages = () => {
    (called ? refetch : getDamagesApi)().then(queryResults => setDamages(queryResults.data?.damages as Partial<Damage>[]));
  };

  useEffect(() => {
    getDamages();
    setFetchDamages(getDamages);
  }, []);

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
      },
      {
        field: 'functionalLocation.floc',
        title: t('label.functionalLocation'),
        type: 'string',
        width: 100,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 80,
        ignoreCase: true,
      },
      {
        field: 'longDescription',
        title: t('label.longDescription'),
        type: 'string',
        width: 80,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'codeGroup.codeGroup',
        title: t('label.codeGroup'),
        type: 'string',
        width: 100,
      },
      {
        field: 'codeGroup.class.class',
        title: t('label.class'),
        type: 'string',
        width: 100,
      },
      {
        field: 'part.part',
        title: t('label.part'),
        type: 'string',
        width: 100,
      },
      {
        field: 'inspectionDrawing.idwg',
        title: t('label.idwg'),
        type: 'string',
        width: 100,
      },
      {
        field: 'grid.grid',
        title: t('label.grid'),
        type: 'string',
        width: 100,
      },
      {
        field: 'accessibility.code',
        title: t('label.accessibility'),
        type: 'string',
        width: 100,
      },
      {
        field: 'notification.notif',
        title: t('label.ian'),
        type: 'string',
        width: 100,
      },
      {
        field: 'notification.externalId',
        title: t('label.notification'),
        type: 'string',
        width: 80,
      },
      {
        field: 'notification.externalStatus',
        title: t('label.notificationStatus'),
        type: 'string',
        width: 80,
      },
      {
        field: 'display2d',
        title: t('label.2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'display3d',
        title: t('label.3d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'coordinates2d',
        title: t('label.coordinates2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'markerCoordinates2d',
        title: t('label.markerCoordinates2d'),
        width: 80,
        type: 'string',
        hidden: true,
      },
      {
        field: 'coordinates3d',
        title: t('label.coordinates3d'),
        width: 80,
        type: 'string',
      },
      {
        field: 'markerCoordinates3d',
        title: t('label.markerCoordinates3d'),
        width: 80,
        type: 'string',
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'string',
        width: 80,
        cellRenderer: 'flagStatus',
        hidden: true,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => setActiveDamage(rowData as Damage)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      flagStatus: (val: string) => val ? t(`label.flagStatus.${val}`) : '',
    },
  }), [damages]);

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<Damage>[] = useMemo(() => ([
    'action',
    'functionalLocation.floc',
    'description',
    'codeGroup.codeGroup',
    'notification.notif',
  ]), [damages]);

  return (
    <div className='h-full'>
      <Grid
        gridName='anomalyManagement-damageGrid'
        queryData={{
          data: damages ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.damage')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      />

      {!!activeDamage && <DamageModal confirmOnExitNoSave isOpen item={activeDamage} onCreatedOrUpdated={fetchDamages}/>}
    </div>
  );
}
