import {gql} from '@apollo/client';
import {FLOC_CATALOGS_COMMON_FIELDS, FLOCS_DETAIL_COMMON_FIELDS, NOTIFICATION_CATALOGS_COMMON_FIELDS, NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS, NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS, NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS, NOTIFICATION_REF_PRIORITY_COMMON_FIELDS, NOTIFICATION_REF_SEVERITY_COMMON_FIELDS, NOTIFICATION_REF_TYPES_COMMON_FIELDS} from '.';

export const NOTIFICATIONS_COMMON_FIELDS = gql`
  fragment NotificationCommonFields on Notification {
      causeId
      causeText
      consequence
      creationDate
      damageId
      damageText
      deckLevel
      description
      detectionId
      effectId
      externalId
      externalStatus
      ffsRequired
      flocId
      id
      isBelowMawt
      isLeak
      isTempRepair
      jobTypeId
      likelihood
      longDescription
      mainWorkCenterId
      notif
      objectId
      plannerGroupId
      plantWorkCenterId
      priority
      repairSystemId
      reportedBy
      requiredEndDate
      requiredStartDate
      ressourceId
      severity
      shutdownId
      status
      statusExport
      typeId
      wrkoId
      userResponsibilityId
  }
`;

export const NOTIFICATIONS_DETAIL_FIELDS = gql`
  ${NOTIFICATIONS_COMMON_FIELDS}
  ${NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS}
  ${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${NOTIFICATION_CATALOGS_COMMON_FIELDS}
  ${NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS}
  ${NOTIFICATION_REF_SEVERITY_COMMON_FIELDS}
  ${NOTIFICATION_REF_PRIORITY_COMMON_FIELDS}
  ${NOTIFICATION_REF_ADDITIONAL_DATA_COMMON_FIELDS}
  ${NOTIFICATION_REF_LIKELIHOOD_COMMON_FIELDS}
  fragment NotificationDetailFields on Notification {
      ...NotificationCommonFields
      notifType {
        ...RefNotifTypeCommonFields
      }
      detection {
        ...RefNotifAdditionalDataCommonFields
      }
      functionalLocation {
        ...FlocDetailFields
      }
      mainWorkCenter {
        ...RefFlocCatalogsCommonFields
      }
      plannerGroup {
        ...RefFlocCatalogsCommonFields
      }
      plantWorkCenter {
        ...RefFlocCatalogsCommonFields
      }
      userResponsibility {
        ...RefNotifCatalogCommonFields
      }
      effect {
        ...RefNotifAdditionalDataCommonFields
      }
      object {
        ...RefNotifAdditionalDataCommonFields
      }
      cause {
        ...RefNotifAdditionalDataCommonFields
      }
      damage {
        ...RefNotifAdditionalDataCommonFields
      }
      notifConsequence {
        ...RefNotifConsequenceCommonFields
      }
      notifLikelihood {
        ...RefNotifLikelihoodCommonFields
      }
      notifSeverity {
        ...RefNotifSeverityCommonFields
      }
      notifPriority {
        ...RefNotifPriorityCommonFields
      }
      shutdown {
        ...RefFlocCatalogsCommonFields
      }
      jobType {
        ...RefNotifCatalogCommonFields
      }
      ressource {
        ...RefNotifCatalogCommonFields
      }
      repairSystem {
        ...RefNotifCatalogCommonFields
      }
  }
`;

