import {useLazyQuery} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {
  FunctionalLocation,
  GetInspectionPointsByFlocIdsQuery,
  InspectionPoint,
} from '@app/graphql/__types__/graphql';
import {INSPECTION_POINTS_GET_BY_FLOC_IDS} from '@app/graphql/requests';

import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {searchArray} from '@app/utils/functions';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuClipboardList, LuList} from 'react-icons/lu';
import InspectionPointItem from './InspectionPointItem';
import InspectionPointListModal from '@app/components/Common/Block/InspectionPoint/InspectionPointBlock/InspectionPointListModal';

type TInspectionPointList = Readonly<{
  floc: Partial<FunctionalLocation>;
  readonly?: boolean;
}>;

/** Floc inspection point list */
export default function InspectionPointList({floc, readonly}: TInspectionPointList) {
  const {t} = useTranslation();
  // const config = useOptimusConfig();
  // const {startLoading, stopLoading} = useLayoutStore();
  const {
    inspectionPoints: flocInspectionPoints,
    setInspectionPoints: setFlocInspectionPoints,
    setFetchInspectionPoints,
  } = useFlocStore();
  // const [allInspectionPoints, setAllInspectionPoints] = useState<InspectionPoint[]>([]);
  const [filteredInspectionPoints, setFilteredInspectionPoints] = useState<Partial<InspectionPoint>[]>();
  const [inspectionPointListModalDisplayed, changeInspectionPointListModalDisplay] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [getInspectionPointListApi, {called, refetch}] = useLazyQuery<GetInspectionPointsByFlocIdsQuery>(INSPECTION_POINTS_GET_BY_FLOC_IDS, {
    variables: {
      flocIds: [floc.id],
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    fetchInspectionPoints();
    setFetchInspectionPoints(fetchInspectionPoints);
  }, [floc.id]);

  useEffect(() => {
    setFilteredInspectionPoints(
      searchArray(flocInspectionPoints ?? [], searchInput, undefined, ['__typename']),
    );
  }, [flocInspectionPoints, searchInput]);

  const fetchInspectionPoints = () => {
    (called ? refetch : getInspectionPointListApi)()
      .then(queryResult => setFlocInspectionPoints((queryResult.data?.inspectionPoints ?? []) as Partial<InspectionPoint>[]));
  };

  return (
    <div>
      <FormGroupHeader
        menuItems={[
          (
            <RadDropdownMenuItem
              key='display-list'
              onClick={() => changeInspectionPointListModalDisplay(true)}
            >
              <LuList className='mr-2'/>

              {t('label.displayList')}
            </RadDropdownMenuItem>
          ),
        ]}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }
      >
        <div className='flex items-center'>
          <LuClipboardList size={20} className='mr-2'/>

          {t('label.checklist')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col w-full gap-1 mb-2 mt-2'>
        {filteredInspectionPoints?.map((item: Partial<InspectionPoint>) =>
          // const eventInspectionPoint = eventInspectionPoints?.find((evtInspectionPoint: Partial<EventInspectionPoint>) => evtInspectionPoint.inspId === item.id);
          (
            <InspectionPointItem
              key={item.id}
              hasMenuContext
              // readonly={readonly || config.getActionIsDisabled('event', 'pointSelect', floc.status)}
              readonly={readonly}
              inspectionPoint={item}
            />
          ),
        )}
      </div>
      {inspectionPointListModalDisplayed && <InspectionPointListModal open hasItems title={t('label.inspectionPointList')} description={`${t('label.functionalLocation').toUpperCase()} ${floc.floc}`} sortOrders={['asc']} items={flocInspectionPoints} onClose={() => changeInspectionPointListModalDisplay(false)}/>}
    </div>
  );
}
