import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {AuthUser} from '@holis/auth-client';

type State = {
  user?: AuthUser;
}

const initialState: State = {
  user: undefined,
};

type Actions = {
    setUser: (user?: AuthUser) => void;
}

type AuthState = State & Actions;

const useAuth = create<AuthState>()(
  immer(set => ({
    ...initialState,
    setUser(user) {
      set({user});
    },
  })),
);

export default useAuth;
