import {EventInspectionPoint, InspectionPoint} from '@app/graphql/__types__/graphql';
import useInspectionPointStore from '@app/stores/insp';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {
  RadCard,
  RadCardContent,
  RadContextMenu,
  RadContextMenuContent,
  RadContextMenuItem,
  RadContextMenuTrigger,
} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';
import {LuCamera, LuExternalLink} from 'react-icons/lu';

type TInspectionPointItem = Readonly<{
  inspectionPoint: Partial<InspectionPoint>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  readonly?: boolean;
  hasMenuContext?: boolean;
}>;

export default function InspectionPointItem({inspectionPoint, eventInspectionPoint, hasMenuContext}: TInspectionPointItem) {
  // const {setInspectionPointToDelete} = useEventStore();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  const {t} = useTranslation();
  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <RadCard className='rounded-md'>
          <RadCardContent className='flex p-2 gap-2 items-center'>
            <div className='self-stretch rounded-md bg-blue-50 p-2 min-w-14 text-center'>
              <div className='text-primary font-extrabold'>
                {inspectionPoint?.class?.class}
              </div>

              <div className='font-extrabold'>
                {inspectionPoint?.position}
              </div>
            </div>

            <div className='flex-1'>
              <div className='font-medium'>
                {inspectionPoint?.description}
              </div>

              <div className='font-medium text-2xs'>
                {inspectionPoint?.functionalLocation?.floc}
              </div>
            </div>

            <div className='flex gap-2 items-center'>
              {(!!inspectionPoint._count?.pictures || !!eventInspectionPoint?.inspectionPoint?._count?.pictures) && (
                <LuCamera className='text-primary' size={20}/>
              )}

              <div
                className='self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center'
                style={{
                  backgroundColor: (eventInspectionPoint?.qualReading?.integrityCondition.color ?? QUALREADING_COLOR) as string,
                }}
              >
                <div className='font-medium'>
                  {eventInspectionPoint?.qualReading?.valCode ?? '-'}
                </div>
              </div>
            </div>
          </RadCardContent>
        </RadCard>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className='mr-2'/>

          {t('label.open')}
        </RadContextMenuItem>

        {/* <RadContextMenuItem
          className='item-destructive'
          disabled={readonly}
          onClick={() => setInspectionPointToDelete(eventInspectionPoint!)}
        >
          <LuTrash2 className='mr-2'/>

          {t('label.removeFromEvent')}
        </RadContextMenuItem> */}
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
