import {gql} from '@apollo/client/core';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS, FLOCS_COUNTER_FIELDS, FLOCS_DETAIL_COMMON_FIELDS, FLOC_VIEWS_COMMON_FIELDS, FLOC_VIEWS_DETAIL_FIELDS} from '../fragments';

export const FLOCS_GET_ALL = gql`
query getFlocs {

  getAllFlocs {
    id
    floc
    tagNumber
    description
    longDescription
    referenceDocument
    catalogId
    parentId
    sectorId
    gridId
    techClassId
    classSece
    integritySystem
    planningPlantId
    plantWorkCenterId
    plannerGroupId
    mainWorkCenterId
    strategyId
    classification
    legislation
    docName
    processFunctionId
    corrosionLoop
    consequenceProdId
    shutdownId
    flagStatus
    externalRef
    catalog
    parent
    classTechnical
    consequenceIntegrity
    strategy
    processFunction
    shutdown
    mainWorkCenter
    planningPlant
    plannerGroup
    plantWorkCenter
    grid
    sector
    site
    class
    consequenceProduction
  }
}`;

export const FLOCS_GET_ALL_AUTOCOMPLETE = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetAllFlocsAutocomplete {
    functionalLocations {
      ...FlocAutocompleteCommonFields
    }
  }
`;

export const FLOC_VIEWS_GET_ALL_AUTOCOMPLETE = gql`
  ${FLOC_VIEWS_COMMON_FIELDS}
  query GetAllFlocViewsAutocomplete {
    flocViews {
      ...FlocViewCommonFields
    }
  }
`;

export const FLOCS_GET_ALL_WITH_DETAIL = gql`
  ${FLOCS_DETAIL_COMMON_FIELDS}
  query GetAllFlocsWithDetail {
    functionalLocations {
      ...FlocDetailFields
    }
  }
`;

export const FLOCS_GET_VIEW_ALL_WITH_DETAIL = gql`
  ${FLOC_VIEWS_DETAIL_FIELDS}
  query GetAllFlocsViewWithDetail {
    flocViews {
      ...FlocViewDetailFields
    }
  }
`;

export const FLOCS_GET_BY_IDWG_ID = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetFlocsByIdwgId($idwgId: Int!) {
    functionalLocations(where: {idwgFlocs: {some: {idwgId: {equals: $idwgId}}}}) {
      ...FlocAutocompleteCommonFields
    }
  }
`;

export const FLOCS_GET_BY_DOC_ID = gql`
  ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetFlocsByDocId($docId: Int!) {
    functionalLocations(where: {flocDocs: {some: {docId: {equals: $docId}}}}) {
      ...FlocAutocompleteCommonFields
    }
  }
`;

export const FLOCS_GET_DETAIL_BY_NUMBER = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
${FLOCS_COUNTER_FIELDS}
query GetFlocDetailByNumber($number: String!) {
  functionalLocation(where: {floc: $number}) {
    ...FlocDetailFields
    ...FlocCounterFields
  }
}`;

export const FLOCS_GET_DETAIL_BY_ID = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
${FLOCS_COUNTER_FIELDS}
query GetFlocDetailById($id: Int!) {
  functionalLocation(where: {id: $id}) {
    ...FlocDetailFields
    ...FlocCounterFields
  }
}`;

export const FLOCS_UPDATE_BY_ID = gql`
${FLOCS_DETAIL_COMMON_FIELDS}
mutation UpdateFlocById($id: Int!, $data: FunctionalLocationUncheckedUpdateInput!) {
  updateOneFunctionalLocation(data: $data, where: {id: $id}) {
    ...FlocDetailFields
  }
}`;

export const FLOCS_GET_COUNTER = gql`
query GetFlocsCounter {
  aggregateFunctionalLocation {
    _count {
      id
    }
  }
}
`;
