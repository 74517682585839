import {useTranslation} from 'react-i18next';
import {renderCodeAndDescription} from '@app/utils/functions';
import React, {InputHTMLAttributes} from 'react';
import {TFieldsBlock, TMaybeCodeDescriptionDatas} from '@app/types/app';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import {MAX_LENGTH_VALIDATORS} from '@app/stores/notification';
import {Notification, GetAllRefFlocCatalogsQuery, RefFlocCatalogs, FunctionalLocation, RefNotifType, GetAllRefNotifTypesQuery, GetAllRefNotifCatalogsQuery, RefNotifCatalog} from '@app/graphql/__types__/graphql';
import {EFieldType, EFLOC_CATALOGS_CATEGORY, ENOTIFICATION_CATALOGS_CATEGORY} from '@app/utils/enums';
import {QueryResult, useQuery} from '@apollo/client';
import {NOTIFICATION_CATALOGS_GET_MANY, NOTIFICATION_REF_TYPES_GET_MANY} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import useNotificationStore from '@app/stores/notification';
import {LuClipboardList} from 'react-icons/lu';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import FlocCard from '@app/components/Common/Block/Floc/FlocBlock/FlocCard';

type TNotificationHeader = Readonly<{
  notification: Partial<Notification>
}>

export default function NotificationHeader({notification}: TNotificationHeader) {
  const {t} = useTranslation();
  const {editNotification, hasFieldError, setSubmitRequested, handleFieldChange, renderAutocomplete} = useNotificationStore();
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY);
  const refNotifCatalogResult = useQuery<GetAllRefNotifCatalogsQuery>(NOTIFICATION_CATALOGS_GET_MANY);
  const refNotifTypeResult = useQuery<GetAllRefNotifTypesQuery>(NOTIFICATION_REF_TYPES_GET_MANY);

  const isFormDisabled = false;
  const BLOCK_IDENTIFICATION: TFieldsBlock = {
    title: 'label.identification',
    fields: [
      {
        label: 'label.notificationNumber',
        field: 'notif',
        fieldType: EFieldType.text,
        className: 'flex w-1/2 min-w-[360px]',
        initialValue: notification!.notif,
        value: editNotification!.notif,
        isDisabled: true,
        hasError: hasFieldError('notification'),
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.NOTIF,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.type',
        field: 'typeId',
        isDisabled: isFormDisabled,
        className: 'flex w-auto min-w-[250px]',
        isRequired: true,
        initialValue: notification!.notifType?.notifType,
        value: editNotification!.notifType?.notifType,
        foreignObject: editNotification?.notifType,
        dbValue: notification!.typeId,
        fieldType: EFieldType.autocomplete,
        renderMenuItemLabel: field => (field as RefNotifType)!.notifType,
        itemsQueryResult: refNotifTypeResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifTypesQuery>)?.data?.refNotifTypes ?? [],
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.description',
        field: 'description',
        fieldType: EFieldType.text,
        hasError: hasFieldError('description'),
        isRequired: true,
        className: 'flex w-full',
        initialValue: notification!.description,
        value: editNotification?.description,
        isDisabled: isFormDisabled,
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.externalId',
        field: 'externalId',
        fieldType: EFieldType.text,
        className: 'flex w-full',
        initialValue: notification!.externalId,
        value: editNotification?.externalId,
        isDisabled: isFormDisabled,
      },
      {
        label: 'label.externalStatus',
        field: 'externalStatus',
        fieldType: EFieldType.text,
        hasError: hasFieldError('externalStatus'),
        className: 'flex w-full',
        initialValue: notification!.externalStatus,
        value: editNotification?.externalStatus,
        isDisabled: isFormDisabled,
      },
    ],
    fieldsClassName: 'w-full flex flex-row flex-wrap',
    fieldClassName: 'inline-flex w-auto',
  };

  const BLOCK_ADDITIONAL_DATA: TFieldsBlock = {
    title: 'label.additionalData',
    fields: [
      {
        label: 'label.tempRepair',
        field: 'isTempRepair',
        value: editNotification?.isTempRepair,
        fieldType: EFieldType.boolean,
        icon: false,
      },
      {
        label: 'label.belowMAWT',
        field: 'isBelowMawt',
        value: editNotification?.isBelowMawt,
        fieldType: EFieldType.boolean,
        icon: false,
      },
      {
        label: 'label.leak',
        field: 'isLeak',
        value: editNotification?.isLeak,
        fieldType: EFieldType.boolean,
        icon: false,
      },
      {
        label: 'label.ffs',
        field: 'ffsRequired',
        value: editNotification?.ffsRequired,
        fieldType: EFieldType.boolean,
        icon: false,
      },
    ],
    fieldClassName: 'w-auto inline-flex flex-row-reverse items-center w-auto',
    labelClassName: 'pt-0 gap-0 w-auto min-w-[120px]',
    fieldsClassName: 'flex-row flex-wrap',
  };

  const BLOCK_RESPONSABILITIES: TFieldsBlock = {
    title: 'label.responsabilities',
    fields: [
      {
        label: 'label.plannerGroup',
        field: 'plannerGroupId',
        initialValue: renderCodeAndDescription(notification!.plannerGroup as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.plannerGroup as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.plannerGroup,
        foreignField: 'plannerGroup',
        dbValue: notification!.plannerGroupId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.mainWorkCenter',
        field: 'mainWorkCenterId',
        isDisabled: isFormDisabled,
        initialValue: renderCodeAndDescription(notification!.mainWorkCenter as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.mainWorkCenter as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.mainWorkCenter,
        foreignField: 'mainWorkCenter',
        dbValue: notification!.mainWorkCenterId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.plantWorkCenter',
        field: 'plantWorkCenterId',
        initialValue: renderCodeAndDescription(notification!.plantWorkCenter as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.plantWorkCenter as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.plantWorkCenter,
        foreignField: 'plantWorkCenter',
        dbValue: notification!.plantWorkCenterId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLANT_WORK_CENTER) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.userResponsible',
        field: 'userResponsibilityId',
        initialValue: renderCodeAndDescription(notification!.userResponsibility as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.userResponsibility as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.userResponsibility,
        foreignField: 'userResponsibility',
        dbValue: notification!.userResponsibilityId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifCatalogsQuery>)?.data?.refNotifCatalogs?.filter((item: Partial<RefNotifCatalog>) => item.category === ENOTIFICATION_CATALOGS_CATEGORY.USER_RESPONSIBLE) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.reportedBy',
        field: 'reportedBy',
        fieldType: EFieldType.text,
        className: 'flex w-full',
        initialValue: notification!.reportedBy,
        value: editNotification!.reportedBy,
        isDisabled: isFormDisabled,
        hasError: hasFieldError('reportedBy'),
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.REPORTED_BY,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
    ],
  };

  const fieldBlocks = [BLOCK_IDENTIFICATION, BLOCK_ADDITIONAL_DATA, BLOCK_RESPONSABILITIES];
  return (
    <FormFieldsBlock
      key='header'
      prefixNode={<div className='flex flex-col gap-y-2'>
        <FormGroupHeader>
          <div className='flex items-center font-bold'>
            <LuClipboardList size={20} className='mr-2'/> {t('label.headerData')}
          </div>
        </FormGroupHeader>
        <FlocCard floc={notification.functionalLocation as FunctionalLocation}/>
      </div>} blockTitleClassName='text-primary1'
      className='text-gray-700' fieldsBlocks={fieldBlocks} onFieldChange={handleFieldChange}
      onFieldBlur={() => setSubmitRequested(true)}/>
  );
}
