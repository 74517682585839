import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteNotificationByIdMutation} from '@app/graphql/__types__/graphql';
import {NOTIFICATIONS_DELETE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useNotificationStore from '@app/stores/notification';
import {useLayoutStore} from '@app/stores/layout';
import {useTranslation} from 'react-i18next';

type TDeleteNotificationModal = IConfirmModal;

export default function DeleteNotificationModal({...restProps}: TDeleteNotificationModal) {
  const {changeDeleteNotificationModalDisplay, deleteNotification, activeNotification} = useNotificationStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteNotificationApi] = useMutation<DeleteNotificationByIdMutation>(NOTIFICATIONS_DELETE_BY_ID);
  const {t} = useTranslation();

  const onConfirm = () => {
    startLoading();
    deleteNotificationApi({
      variables: {id: activeNotification!.id},
    }).then(() => {
      deleteNotification();
      AppNotifications.success(t('message.success.deleteNotification'));
    }).catch((_err: Error) => {
      AppNotifications.error(t('message.error.deleteNotification'));
    }).finally(() => {
      stopLoading();
    });
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteNotification')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteNotificationModalDisplay(false)}
      {...restProps}
    />
  );
}
