import {gql} from '@apollo/client';
import {FLOCS_DETAIL_COMMON_FIELDS} from './flocs';
import {SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS} from './refSchedulingPlanTypes';
import {SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS} from './refSchedulingPlanStrategies';
import {FLOC_CATALOGS_COMMON_FIELDS} from './refFlocCatalogs';
import {APP_PLAN_STATUSES_COMMON_FIELDS} from './planStatuses';

export const SCHEDULING_PLANS_COMMON_FIELDS = gql`
  ${FLOCS_DETAIL_COMMON_FIELDS}
  ${SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS}
  ${SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS}
  ${FLOC_CATALOGS_COMMON_FIELDS}
  ${APP_PLAN_STATUSES_COMMON_FIELDS}
  fragment PlanCommonFields on Plan {
    id
    description
    plan
    flocId
    functionalLocation {
      ...FlocDetailFields 
    }
    typeId
    type {
      ...RefPlanTypeCommonFields
    }
    revision
    strategyId
    strategy {
      ...RefPlanStrategyCommonFields
    }
    startDate
    schedulingUnit
    status
    statusPlan {
      ...PlanStatusCommonFields
    }
    notes
    mainWorkCenterId
    mainWorkCenter {
      ...RefFlocCatalogsCommonFields
    }
    plannerGroupId
    plannerGroup {
      ...RefFlocCatalogsCommonFields
    }
  }
`;

export const SCHEDULING_PLANS_COUNTER_FIELDS = gql`
  fragment PlanCounterFields on Plan {
    _count {
      planFlocs
    }
  }
`;
