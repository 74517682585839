import {useLazyQuery, useMutation} from '@apollo/client';
import {CreateNotificationMutation, Damage, FunctionalLocation, GetDamagesHavingEventByFlocIdsQuery, Notification} from '@app/graphql/__types__/graphql';
import {DAMAGES_GET_HAVING_EVENT_BY_FLOC_IDS, NOTIFICATION_DAMAGES_CREATE_MANY, NOTIFICATIONS_CREATE} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useNotificationStore, {ZOD_NOTIFICATION_DATAS} from '@app/stores/notification';
import {setObjValueByPath} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';

import {useTranslation} from 'react-i18next';
import Form from './components/Form';
import Footer from './components/Footer';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import {useEffect, useRef, useState} from 'react';
import SingleFormModal from '@app/components/Modal/SingleFormModal';
import {useEventStore} from '@app/stores/event';
import DamageSelectionModal from '../../Damage/DamageBlock/DamageSelectionModal';
import FlocSelectionModal from '../../Floc/FlocBlock/FlocSelectionModal';

type TNotificationModal = Readonly<{
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onCreated: () => void;
}>;

export default function NotificationNewModal({onOpenChange, isOpen, onCreated}: TNotificationModal) {
  const {t} = useTranslation();

  const {editNotification, setEditNotification, updateNotificationDataField, updateNotificationData, setUpdateNotificationData, updateNotification, createNotificationWorkflowFunc, updateNotificationFieldError, hasError, changeDamageSelectionNewModalDisplay, damageSelectionModalOpen, selectedDamages, setSelectedDamages, activeNotification, setActiveNotification, eventDamages} = useNotificationStore();
  const [createNotificationApi] = useMutation<CreateNotificationMutation>(NOTIFICATIONS_CREATE);
  const [createNotificationDamagesApi] = useMutation<CreateNotificationMutation>(NOTIFICATION_DAMAGES_CREATE_MANY);
  const {fetchEventNotifications} = useEventStore();
  const [damagesLoading, setDamagesLoading] = useState<boolean>(false);
  const [damages, setDamages] = useState<Partial<Damage>[]>();
  const [selectedIds, setSelectedIds] = useState<number[]>();
  const [getDamagesByFlocIdsApi] = useLazyQuery<GetDamagesHavingEventByFlocIdsQuery>(DAMAGES_GET_HAVING_EVENT_BY_FLOC_IDS);
  // const [getDamagesByFlocIdsAndEventApi] = useLazyQuery<GetDamagesByFlocIdsAndEventQuery>(DAMAGES_GET_BY_FLOC_IDS_AND_EVENT);

  const onValidateDamageSelection = (selectedItems: Partial<Damage>[]) => {
    setSelectedDamages(selectedItems);
    changeDamageSelectionNewModalDisplay(false);
  };

  const {user} = useHolisAuth();

  const zodFormObject = z.object(ZOD_NOTIFICATION_DATAS(t));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        description: '',
        typeId: undefined,
        flocId: editNotification?.flocId,
      },
    });
  const htmlForm = useRef<HTMLFormElement>(null);

  // Update notification value in store (will update updateNotificationData)
  const updateNotificationDataValue = (field: string, value: unknown) => {
    console.log('update notif');
    const editedNotification = {...editNotification};
    setObjValueByPath(editedNotification, field, value);
    updateNotificationDataField(field, value);

    setEditNotification(editedNotification);
  };

  // Watch form value changes
  form.watch((datas, {name, type}) => {
    if (type === 'change') {
      updateNotificationDataValue(name!, datas[name!]);
    }
  });

  // Handle notification creation error.
  const handleNotificationCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title');
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`notif`)')) {
        errorMessage = t('message.error.unique.anomalyManagement.notification.notif');
        updateNotificationFieldError('notif', true);
      }
    }

    AppNotifications.error(errorMessage);
  };

  const onSelectFloc = (selectedItems: Partial<FunctionalLocation>[]) => {
    if (selectedItems.length) {
      const floc = selectedItems[0];
      form.setValue('flocId', floc.id!);
      setActiveNotification({
        ...activeNotification,
        functionalLocation: floc as FunctionalLocation,
        flocId: floc.id,
      });
    } else {
      setActiveNotification();
    }
  };

  // Update notification state in store and create workflow record.
  const updateNotificationState = (newData: Partial<Notification>, actionDate: Date, isNew?: boolean) => {
    const newNotification = {
      ...editNotification,
      ...newData,
    } as Partial<Notification>;
    if (newNotification.status !== editNotification?.status) {
      createNotificationWorkflowFunc?.({
        variables: {
          data: {
            notifId: newNotification.id,
            status: newNotification.status,
            date: actionDate,
            userLogin: user?.username,
          },
        },
      });
    }

    updateNotification(newNotification, isNew);
    setUpdateNotificationData({});
    fetchEventNotifications?.();
    onOpenChange?.(false);
  };

  // Handle actual notification creation.
  const handleCreateNotification = async () => {
    if (
      hasError() // Check if any field in updateNotificationData has an error.
    ) {
      AppNotifications.error(t('message.error.requiredFields'));
      return;
    }

    const actionDate = new Date();
    await createNotificationApi({variables: {data: {
      ...updateNotificationData,
      reportedBy: user?.username,
    }}}).then(async newData => {
      const notification = newData.data?.createOneNotification as Partial<Notification>;
      updateNotificationState(notification, actionDate, true);
      if (selectedDamages?.length) {
        await createNotificationDamagesApi({
          variables: {
            data: selectedDamages!.map((dmg: Partial<Damage>) => ({
              dmgeId: dmg.id!,
              notifId: notification.id!,
              qualReadingId: eventDamages?.find(item => item.dmgeId === dmg.id!)?.qualReadingId ?? null,
            })),
          },
        }).then(() => {
          AppNotifications.success(t('message.success.addNotification'));
        });
      } else {
        AppNotifications.success(t('message.success.addNotification'));
      }

      onCreated();
    }).catch((err: Error) => {
      handleNotificationCreateError(err);
    });
  };

  useEffect(() => {
    console.log('load damages', editNotification?.flocId);
    if (editNotification?.flocId) {
      setDamagesLoading(true);
      // if (editNotification.wrkoId) {
      //   getDamagesByFlocIdsAndEventApi({
      //     variables: {
      //       flocIds: [activeNotification?.flocId],
      //       evtId: editNotification.wrkoId,
      //     },
      //     fetchPolicy: 'no-cache',
      //   }).then(queryResult => setDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])).finally(() => setDamagesLoading(false));
      // } else {
      getDamagesByFlocIdsApi({
        variables: {
          flocIds: [activeNotification?.flocId],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setDamages((queryResult.data?.damages ?? []) as Partial<Damage>[])).finally(() => setDamagesLoading(false));
      // }
    }
  }, [editNotification?.flocId]);

  useEffect(() => {
    setSelectedIds(selectedDamages?.map((dmg: Partial<Damage>) => dmg.id!) ?? []);
  }, [selectedDamages]);
  return (
    <>
      {!!editNotification?.functionalLocation && <SingleFormModal
        isOpen={isOpen}
        isTransparent={damageSelectionModalOpen}
        title={t('label.notificationCreation')}
        description={<>
          <div className='text-lg'><span className='text-primary font-semibold'>{editNotification?.functionalLocation?.floc ?? ''} - </span><span>{editNotification?.functionalLocation?.description ?? ''}</span></div>
          <div>{t('label.itemCreateDescription')}</div>
        </>}
        footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()}/>}
        onOpenChange={onOpenChange}
      >
        <RadForm {...form}>
          <form ref={htmlForm} onSubmit={form.handleSubmit(handleCreateNotification, console.log)}>
            <Form/>
          </form>
        </RadForm>
      </SingleFormModal>}
      {damageSelectionModalOpen && !damagesLoading && <DamageSelectionModal open selectedIds={selectedIds} items={damages ?? []} onClose={() => changeDamageSelectionNewModalDisplay(false)} onValidate={onValidateDamageSelection}/>}
      {!editNotification?.functionalLocation && <FlocSelectionModal open isRequired isMultiple={false} onClose={() => setActiveNotification()} onValidate={onSelectFloc}/>}
    </>
  );
}
