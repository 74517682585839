import {Grid, TGridProps} from '@app/components/Common/Grid';
import UserModal from '@app/components/Modal/Admin/User';
import useUsersStore from '@app/stores/users';
import {AuthUser} from '@holis/auth-client';
import {RadButton} from '@holis/react-ui/rad';
import {t} from 'i18next';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useMemo} from 'react';
import {MdOpenInNew} from 'react-icons/md';

export default function Users() {
  const {users, editingUser, setEditingUser, roles} = useUsersStore();

  const rolesByUser:Record<string, string> = useMemo(() => {
    const result = {} as Record<string, string>;
    roles?.forEach(role => {
      role.usersIds?.forEach(user => {
        result[user] = role.role!;
      });
    });
    return result;
  }, [roles]);

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: 'action',
        type: 'string',
        titleHidden: true,
        cellRenderer: 'action',
        filter: false,
      },
      {
        field: 'username',
        title: t('label.username'),
        type: 'string',
        width: 200,
        sort: 'asc',
      },
      {
        field: 'email',
        title: t('label.email'),
        type: 'string',
        width: 300,
      },
      {
        field: 'firstName',
        title: t('label.firstName'),
        type: 'string',
        width: 200,
      },
      {
        field: 'lastName',
        title: t('label.lastName'),
        type: 'string',
        width: 200,
      },
      {
        field: 'role',
        title: t('label.role'),
        type: 'string',
        width: 200,
        cellRenderer: 'role',
      },
    ],
    cellRenderers: {
      action: (_val: string, user: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => setEditingUser(user as AuthUser)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      role: (_val: unknown, user: unknown) => rolesByUser[(user as AuthUser).id] ?? '-',
    },
  }), [users, rolesByUser]);

  const columnsAlwaysVisible: TNestedKeys<{action: string} & AuthUser>[] = useMemo(() => ([
    'action',
  ]), [users]);

  return (
    <div className='h-full'>
      <Grid
        gridName='admin-usersGrid'
        queryData={{
          data: users ?? [],
          loading: !users,
        }}
        gridProps={gridProps}
        columnsAlwaysVisible={columnsAlwaysVisible}
      />

      {editingUser && <UserModal open item={editingUser!} onClose={() => setEditingUser(undefined)}/>}
    </div>
  );
}
