/* eslint-disable camelcase */
import {TPDFFile} from '@app/components/Common/Viewer/PDFViewer';
import {Cml, Damage, Drawing, EventCml, EventDamage, FunctionalLocation, Grid, IdwgFloc, IdwgGrid, IdwgTechnique, InspectionDrawing, InspectionDrawingUncheckedUpdateInput, RefEventTechnique} from '@app/graphql/__types__/graphql';
import {TDimension, TMarkupObjects} from '@app/types/app';
import * as fabric from 'fabric';
import {TFunction} from 'i18next';
import _ from 'lodash';
import {ReactZoomPanPinchState} from 'react-zoom-pan-pinch';
import {z} from 'zod';
import {createStore, useStore} from 'zustand';
import {immer} from 'zustand/middleware/immer';

export const TAB_CMLS = 0;
export const TAB_DAMAGES = 1;
export const CML_PREFIX = 'cml-';
export const DAMAGE_PREFIX = 'dmg-';
export const IDWG_PREFIX = 'idwg-';
export const IDWG_NUMBER_PREFIX = 'idwg-number-';

// All state in inspection drawing page (list, detail modal, cml modal, damage modal)
type State = {
    canvas?: fabric.Canvas | null;
    currentBgImg?: HTMLImageElement | null | HTMLCanvasElement;
    currentBgDimension?: TDimension;
    bgImg?: HTMLImageElement | null | HTMLCanvasElement;
    bgDimension?: TDimension;
    activeInspectionDrawing?: Partial<InspectionDrawing>|null;
    inspectionDrawings?: Partial<InspectionDrawing>[];
    deleteInspectionDrawingModalOpen: boolean;
    flocToDelete?: Partial<FunctionalLocation>;
    gridToDelete?: Partial<Grid>;
    updateData: InspectionDrawingUncheckedUpdateInput
    editIdwg?: Partial<InspectionDrawing> | null;
    allMarkups?: Record<string, TMarkupObjects>;
    viewerState?: TZoomViewerState;
    initialCanvasWidth?: number;
    initialCanvasHeight?: number;
    scale: number;
    selectedTab?: number;
    modalIdwgDetailShown: boolean;
    fieldErrors: Record<string, boolean>;
    uploadFile?: File;
    latestEventCml?: Record<number, Partial<EventCml> | null>;
    cmlShapeColors?: Record<number, string | null>;
    latestEventDamages?: Record<number, Partial<EventDamage> | null>;
    damageShapeColors?: Record<number, string | null>;
    activeMarkupId?: string;
    fileUploadCounter: number;
    currentDrawingSrc?: TPDFFile;
    drawingSrc?: TPDFFile;
    cmls?: Partial<Cml>[];
    damages?: Partial<Damage>[];
    idwgGrids?: Partial<IdwgGrid>[];
    idwgFlocs?: Partial<IdwgFloc>[];
    idwgTechniques?: Partial<IdwgTechnique>[];
    damageMarkupsShown: boolean;
    cmlMarkupsShown: boolean;
    fetchCmls?: () => void;
    fetchDamages?: () => void;
    cmlInactiveShown: boolean;
    damageInactiveShown: boolean;
    fetchInspectionDrawings?: () => void;
    fileDrawing?: Partial<Drawing> | null;
    nativeUploadFile?: File;
    flocsSelectionDisplayed: boolean;
    gridsSelectionDisplayed: boolean;
    flocs?: Partial<FunctionalLocation>[];
    grids?: Partial<Grid>[];
    techniques?: Partial<RefEventTechnique>[];
    fetchFileDrawing?: () => void;
    cmlsSelectionDisplayed: boolean;
    damagesSelectionDisplayed: boolean;
    idwgFlocCmls?: Partial<Cml>[];
    idwgFlocDamages?: Partial<Damage>[];
    lastCmlUpdated?: Partial<Cml>;
    lastCmlUpdatedAt?: Date;
    lastDamageUpdated?: Partial<Damage>;
    lastDamageUpdatedAt?: Date;
    cmlDisplay2dUpdated?: Partial<Cml>;
    damageDisplay2dUpdated?: Partial<Damage>;
    pointFlocIds?: number[];
  }

const initialState: State = {
  deleteInspectionDrawingModalOpen: false,
  flocToDelete: undefined,
  gridToDelete: undefined,
  updateData: {},
  editIdwg: null,
  allMarkups: {},
  bgImg: null,
  activeInspectionDrawing: null,
  viewerState: undefined,
  initialCanvasWidth: undefined,
  initialCanvasHeight: undefined,
  scale: 1,
  selectedTab: undefined,
  modalIdwgDetailShown: false,
  fieldErrors: {},
  uploadFile: undefined,
  bgDimension: undefined,
  currentBgImg: undefined,
  latestEventDamages: undefined,
  damageShapeColors: undefined,
  cmlShapeColors: undefined,
  latestEventCml: undefined,
  activeMarkupId: undefined,
  fileUploadCounter: 0,
  drawingSrc: undefined,
  currentDrawingSrc: undefined,
  currentBgDimension: undefined,
  cmls: undefined,
  damages: undefined,
  idwgGrids: undefined,
  idwgFlocs: undefined,
  idwgTechniques: undefined,
  damageMarkupsShown: true,
  cmlMarkupsShown: true,
  cmlInactiveShown: false,
  damageInactiveShown: false,
  fetchInspectionDrawings: undefined,
  fileDrawing: undefined,
  nativeUploadFile: undefined,
  flocsSelectionDisplayed: false,
  gridsSelectionDisplayed: false,
  flocs: undefined,
  techniques: undefined,
  fetchFileDrawing: undefined,
  cmlsSelectionDisplayed: false,
  damagesSelectionDisplayed: false,
  grids: undefined,
  idwgFlocCmls: undefined,
  idwgFlocDamages: undefined,
  lastCmlUpdated: undefined,
  lastCmlUpdatedAt: undefined,
  lastDamageUpdated: undefined,
  lastDamageUpdatedAt: undefined,
  cmlDisplay2dUpdated: undefined,
  damageDisplay2dUpdated: undefined,
  pointFlocIds: undefined,
};

export const MAX_LENGTH_VALIDATORS = {
  IDWG: 45,
  DESCRIPTION: 255,
  DOCNAME: 100,
  REVISION: 5,
  CML_POSITION: 10,
  CML_DIAMETER: 5,
  CML_DESCRIPTION: 50,
  CML_LONG_DESCRIPTION: 500,
  CML_EXTERNAL_REF: 45,
  CML_TECHNIQUE: 20,
  DMG_DESCRIPTION: 50,
  DMG_LONG_DESCRIPTION: 255,
};

export const ZOD_IDWG_DATAS = (t: TFunction) => ({
  idwg: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.IDWG).min(1, {message: t('message.error.form.required')}),
  revision: z.string().max(MAX_LENGTH_VALIDATORS.REVISION),
  description: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, {message: t('message.error.form.required')}),
  sectorId: z.number({required_error: t('message.error.form.required')}),
  docName: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.DOCNAME).min(1, {message: t('message.error.form.required')}),
});

type TZoomViewerState = {
    zoomPoint?: fabric.Point
} & ReactZoomPanPinchState;

type Actions = {
    setCanvasInfos: (canvas: fabric.Canvas | null, initialCanvasWidth: number, initialCanvasHeight: number) => void;
    setCanvas: (canvas: fabric.Canvas | null) => void;
    setInspectionDrawings: (inspectionDrawings?: Partial<InspectionDrawing>[]) => void;
    setActiveInspectionDrawing: (activeInspectionDrawing: Partial<InspectionDrawing> | null) => void;
    updateInspectionDrawing: (inspectionDrawing: Partial<InspectionDrawing>) => void;
    deleteInspectionDrawing: (inspectionDrawings?: Partial<InspectionDrawing>) => void;
    deleteIdwgFloc: (flocToDelete?: Partial<FunctionalLocation>, idwg?: Partial<InspectionDrawing>) => void;
    deleteIdwgGrid: (gridToDelete?: Partial<Grid>, idwg?: Partial<InspectionDrawing>) => void;
    changeDeleteInspectionDrawingModalDisplay: (isOpen: boolean) => void;
    setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => void;
    setGridToDelete: (gridToDelete?: Partial<Grid>) => void;
    resetData: () => void;
    setBgImg: (bgImg: HTMLImageElement|HTMLCanvasElement) => void;
    setUpdateData: (updateData: InspectionDrawingUncheckedUpdateInput) =>void;
    setEditIdwg: (idwg: Partial<InspectionDrawing> | null) => void;
    isSaved: () => boolean;
    hasError: ()=>boolean;
    hasFieldError: (field: string, forceCheck?: boolean)=>boolean;
    addCmlMarkup: (cml: Partial<Cml>, markup: TMarkupObjects) => boolean;
    changeMarkupVisible: (id: string, visible: boolean) => void;
    addDamageMarkup: (damage: Partial<Damage>, markup: TMarkupObjects) => boolean;
    clearAllMarkups: () => boolean;
    removeAllMarkups: (prefix?: string, exceptKeys?: string[]) => Record<string, TMarkupObjects> | undefined;
    removeAllCmlMarkups: (exceptIds?: number[]) => Record<string, TMarkupObjects> | undefined;
    removeAllDamageMarkups: (exceptIds?: number[]) => Record<string, TMarkupObjects> | undefined;
    hideAllMarkups: (prefix?: string, exceptKeys?: string[]) => boolean;
    hideAllCmlMarkups: (exceptIds?: number[]) => boolean;
    hideAllDamageMarkups: (exceptIds?: number[]) => boolean;
    setViewerState: (viewerState?: Partial<TZoomViewerState>) => void;
    setScale: (scale: number) => void;
    setSelectedTab: (selectedTab?: number) => void;
    cancelEditData: () => void;
    changeModalIdwgDetailShown: (modalIdwgDetailShown: boolean) => void;
    updateDataField: (field: string, value: unknown) => void;
    setFieldErrors: (fieldErrors?: Record<string, boolean>) => void;
    setUploadFile: (uploadFile?: File) => void;
    updateFieldError: (field: string, value: boolean) => void;
    setBgInfos: (bgImg?: HTMLCanvasElement | HTMLImageElement | null, bgDimension?: TDimension, isCurrentBg?: boolean) => void;
    getLatestEventCmlByCmlId: (cmlId: number) => Partial<EventCml> | undefined | null;
    addLatestEventCml: (cmlId: number, eventCml: Partial<EventCml> | null) => void;
    getLatestEventDamageByDmgId: (dmgId: number) => Partial<EventDamage> | undefined | null;
    addLatestEventDamage: (dmgId: number, eventDamage: Partial<EventDamage> | null) => void;
    getCmlShapeColor: (cmlId: number) => string | undefined | null;
    addCmlShapeColor: (cmlId: number, color: string | null) => void;
    getDamageShapeColor: (dmgId: number) => string | undefined | null;
    addDamageShapeColor: (dmgId: number, color: string | null) => void;
    setActiveMarkupId: (activeMarkupId?: string) => void;
    setDrawingSrc: (drawingSrc?: TPDFFile, isCurrent?: boolean) => void;
    setCmls: (cmls?: Partial<Cml>[]) => void;
    setDamages: (damages?: Partial<Damage>[]) => void;
    setIdwgGrids: (idwgGrids?: Partial<IdwgGrid>[]) => void;
    setIdwgFlocs: (idwgFlocs?: Partial<IdwgFloc>[]) => void;
    setIdwgTechniques: (idwgTechniques?: Partial<IdwgTechnique>[]) => void;
    incrementFileUploadCounter: () => void;
    changeDamageMarkupsDisplay: (damageMarkupsShown: boolean) => void;
    changeCmlMarkupsDisplay: (cmlMarkupsShown: boolean) => void;
    setFetchCmls: (fetchCmls?: () => void) => void;
    setFetchDamages: (fetchDamages?: () => void) => void;
    deleteMarkupsById: (id: string) => void;
    changeCmlInactiveShown: (cmlInactiveShown: boolean) => void;
    changeDamageInactiveShown: (damageInactiveShown: boolean) => void;
    removeCmlMarkup: (cml: Partial<Cml>) => boolean;
    removeDamageMarkup: (damage: Partial<Damage>) => boolean;
    setFetchInspectionDrawings: (fetchInspectionDrawings?: () => void) => void;
    setFileDrawing: (fileDrawing?: Partial<Drawing> | null) => void;
    setNativeUploadFile: (nativeUploadFile?: File) => void;
    changeFlocSelectionModalDisplay: (flocsSelectionDisplayed: boolean) => void;
    changeCmlsSelectionModalDisplay: (cmlsSelectionDisplayed: boolean) => void;
    changeDamagesSelectionModalDisplay: (damagesSelectionDisplayed: boolean) => void;
    setGridsSelectionDisplay: (gridsSelectionDisplayed: boolean) => void;
    setFlocs: (flocs?: Partial<FunctionalLocation>[]) => void;
    setFetchFileDrawing: (fetchFileDrawing?: () => void) => void;
    setGrids: (grids?: Partial<Grid>[]) => void;
    setTechniques: (techniques?: Partial<RefEventTechnique>[]) => void;
    setIdwgFlocCmls: (idwgFlocCmls?: Partial<Cml>[]) => void;
    setIdwgFlocDamages: (idwgFlocDamages?: Partial<Damage>[]) => void;
    changeLastCmlUpdated: (lastCmlUpdated?: Partial<Cml>) => void;
    changeLastDamageUpdated: (lastDamageUpdated?: Partial<Damage>) => void;
    changeCmlDisplay2dUpdated: (cmlDisplay2dUpdated?: Partial<Cml>) => void;
    changeDamageDisplay2dUpdated: (damageDisplay2dUpdated?: Partial<Damage>) => void;
    setPointFlocIds: (pointFlocIds?: number[]) => void;
}

const getIdwgUpdateState = (state: Partial<State>, inspectionDrawingChanges: Partial<InspectionDrawing>) : Partial<State> => {
  const newActiveInspectionDrawing: Partial<InspectionDrawing> = {
    ...state.activeInspectionDrawing,
    ...inspectionDrawingChanges,
  };
  const inspectionDrawings: Partial<InspectionDrawing>[] = [
    ...(state.inspectionDrawings ?? []),
  ];
  const newState: Partial<State> = {};
  if (inspectionDrawings) {
    const index = inspectionDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === newActiveInspectionDrawing.id);
    if (index > -1) {
      inspectionDrawings[index] = newActiveInspectionDrawing;
      newState.inspectionDrawings = inspectionDrawings;
    }
  }

  newState.activeInspectionDrawing = newActiveInspectionDrawing;
  newState.editIdwg = {
    ...newState.activeInspectionDrawing,
  };

  return newState;
};

export const createIdwgStore = (initValues?: Partial<State>) => createStore<State & Actions>()(
  immer((set, get) => ({
    ...initialState,
    ...initValues,
    setTechniques(techniques) {
      set({techniques});
    },
    setIdwgTechniques(idwgTechniques) {
      set({idwgTechniques});
    },
    setCanvas(canvas) {
      set({canvas});
    },
    setPointFlocIds(pointFlocIds) {
      set({pointFlocIds});
    },
    changeCmlDisplay2dUpdated(cmlDisplay2dUpdated) {
      const state = get();
      set({
        cmlDisplay2dUpdated,
        cmls: cmlDisplay2dUpdated?.id ? state.cmls?.map(item => item.id === cmlDisplay2dUpdated?.id ? cmlDisplay2dUpdated : item) : state.cmls,
      });
    },
    changeDamageDisplay2dUpdated(damageDisplay2dUpdated) {
      const state = get();
      set({
        damageDisplay2dUpdated,
        damages: damageDisplay2dUpdated?.id ? state.damages?.map(item => item.id === damageDisplay2dUpdated?.id ? damageDisplay2dUpdated : item) : state.damages,
      });
    },
    changeLastCmlUpdated(lastCmlUpdated) {
      set({lastCmlUpdated, lastCmlUpdatedAt: new Date()});
    },
    changeLastDamageUpdated(lastDamageUpdated) {
      set({lastDamageUpdated, lastDamageUpdatedAt: new Date()});
    },
    setIdwgFlocCmls(idwgFlocCmls) {
      set({idwgFlocCmls});
    },
    setIdwgFlocDamages(idwgFlocDamages) {
      set({idwgFlocDamages});
    },
    setGrids(grids) {
      set({grids});
    },
    changeCmlsSelectionModalDisplay(cmlsSelectionDisplayed) {
      set({cmlsSelectionDisplayed});
    },
    changeDamagesSelectionModalDisplay(damagesSelectionDisplayed) {
      set({damagesSelectionDisplayed});
    },
    setFetchFileDrawing(fetchFileDrawing) {
      set({fetchFileDrawing});
    },
    setFlocs(flocs) {
      set({flocs});
    },
    changeFlocSelectionModalDisplay: (flocsSelectionDisplayed: boolean) => set({
      flocsSelectionDisplayed,
    }),
    setGridsSelectionDisplay: (gridsSelectionDisplayed: boolean) => set({
      gridsSelectionDisplayed,
    }),
    setNativeUploadFile(nativeUploadFile) {
      set({nativeUploadFile});
    },
    setFileDrawing(fileDrawing) {
      set({fileDrawing});
    },
    setFetchInspectionDrawings(fetchInspectionDrawings) {
      set({fetchInspectionDrawings});
    },
    changeDamageInactiveShown(damageInactiveShown) {
      set({damageInactiveShown});
    },
    changeCmlInactiveShown(cmlInactiveShown) {
      set({cmlInactiveShown});
    },
    setFetchCmls(fetchCmls) {
      set({fetchCmls});
    },
    setFetchDamages(fetchDamages) {
      set({fetchDamages});
    },
    changeCmlMarkupsDisplay(cmlMarkupsShown) {
      set({cmlMarkupsShown});
    },
    changeDamageMarkupsDisplay(damageMarkupsShown) {
      set({damageMarkupsShown});
    },
    setIdwgFlocs(idwgFlocs) {
      set({idwgFlocs});
    },
    setIdwgGrids(idwgGrids) {
      set({idwgGrids});
    },
    setCmls(cmls) {
      get().removeAllCmlMarkups(cmls?.map(item => item.id!));
      set({cmls});
    },
    setDamages(damages) {
      get().removeAllDamageMarkups(damages?.map(item => item.id!));
      set({damages});
    },
    setDrawingSrc(drawingSrc?: TPDFFile, isCurrent?: boolean) {
      const newState: Partial<State> = {drawingSrc};
      if (isCurrent === true) {
        newState.currentDrawingSrc = drawingSrc;
      }

      set(newState);
    },
    setActiveMarkupId(activeMarkupId) {
      set({activeMarkupId});
    },
    getCmlShapeColor: (cmlId: number) => get().cmlShapeColors?.[cmlId],
    addCmlShapeColor: (cmlId: number, color: string | null) => set(state => (
      {
        cmlShapeColors: {
          ...state.cmlShapeColors,
          [cmlId]: color,
        },
      }
    )),
    getDamageShapeColor: (dmgId: number) => get().damageShapeColors?.[dmgId],
    addDamageShapeColor: (dmgId: number, color: string | null) => set(state => (
      {
        damageShapeColors: {
          ...state.damageShapeColors,
          [dmgId]: color,
        },
      }
    )),
    addLatestEventCml: (cmlId: number, eventCml: Partial<EventCml> | null) => set(state => ({
      latestEventCml: {
        ...state.latestEventCml,
        [cmlId]: eventCml,
      },
    })),
    addLatestEventDamage: (dmgId: number, eventDamage: Partial<EventDamage> | null) => set(state => ({
      latestEventDamages: {
        ...state.latestEventDamages,
        [dmgId]: eventDamage,
      },
    })),
    getLatestEventCmlByCmlId: (cmlId: number) => get().latestEventCml?.[cmlId],
    getLatestEventDamageByDmgId: (dmgId: number) => get().latestEventDamages?.[dmgId],
    updateFieldError: (field: string, value: boolean) => set(state => ({
      fieldErrors: {
        ...state.fieldErrors,
        [field]: value,
      },
    })),
    setUploadFile: (uploadFile?: File) => set({uploadFile, fileUploadCounter: get().fileUploadCounter + 1, drawingSrc: uploadFile ?? get().currentDrawingSrc}),
    incrementFileUploadCounter: () => set({fileUploadCounter: get().fileUploadCounter + 1}),
    changeMarkupVisible(id: string, visible: boolean) {
      const state = get();
      const markup: TMarkupObjects | undefined = state.allMarkups?.[id];
      if (markup) {
        for (const [_key, obj] of Object.entries(markup)) {
          obj.visible = visible;
        }
      }

      state.canvas?.renderAll();
    },
    setFieldErrors(fieldErrors?: Record<string, boolean>) {
      set({
        fieldErrors: fieldErrors ?? {},
      });
    },
    updateDataField: (field: string, value: unknown) => set(state => ({
      updateData: {
        ...state.updateData,
        [field]: {
          set: value,
        },
      },
      fieldErrors: {
        ...state.fieldErrors,
        [field]: false,
      },
    })),
    changeModalIdwgDetailShown: (modalIdwgDetailShown: boolean) => set({modalIdwgDetailShown}),
    cancelEditData: () => set(state => ({
      bgImg: state.currentBgImg,
      bgDimension: state.currentBgDimension,
      updateData: {},
      drawingSrc: state.currentDrawingSrc,
      fileUploadCounter: 0,
      uploadFile: undefined,
      fieldErrors: {},
      editIdwg: {...state.activeInspectionDrawing},
    })),
    setSelectedTab(selectedTab?: number) {
      set({selectedTab});
    },
    setScale(scale: number) {
      set({scale});
    },
    setViewerState(viewerState) {
      set({viewerState: {
        ...get().viewerState,
        ...viewerState,
      } as TZoomViewerState});
    },
    clearAllMarkups() {
      const {canvas} = get();
      canvas?.getObjects().forEach((o:fabric.FabricObject) => {
        canvas.remove(o);
      });
      set({
        allMarkups: {},
      });
      return true;
    },
    removeAllMarkups(prefix?: string, exceptKeys?: string[]) {
      const {allMarkups, canvas} = get();
      const markupsChanged: string[] = [];
      Object.entries(allMarkups ?? {}).forEach(([key, value]) => {
        if ((!prefix || key.startsWith(prefix)) && !(exceptKeys && !!exceptKeys?.includes(key))) {
          canvas?.remove(...Object.values(value));
          markupsChanged.push(key);
        }
      });

      if (markupsChanged.length) {
        const newAllMarkups = _.omit(allMarkups, markupsChanged);
        set({
          allMarkups: newAllMarkups,
        });
        return newAllMarkups;
      }

      return allMarkups;
    },
    removeAllCmlMarkups(exceptIds?: number[]) {
      return get().removeAllMarkups(CML_PREFIX, exceptIds?.map(id => `${CML_PREFIX}${id}`));
    },
    removeAllDamageMarkups(exceptIds?: number[]) {
      return get().removeAllMarkups(DAMAGE_PREFIX, exceptIds?.map(id => `${DAMAGE_PREFIX}${id}`));
    },
    hideAllMarkups(prefix?: string, exceptKeys?: string[]) {
      const {allMarkups} = get();
      Object.entries(allMarkups ?? {}).forEach(([key, value]) => {
        if ((!prefix || key.startsWith(prefix)) && !(exceptKeys && !!exceptKeys?.includes(key))) {
          Object.entries(value).forEach(([_keyObj, obj]) => {
            obj.visible = false;
          });
        }
      });
      set({
        allMarkups: {...allMarkups},
      });
      return true;
    },
    hideAllCmlMarkups(exceptIds?: number[]) {
      return get().hideAllMarkups(CML_PREFIX, exceptIds?.map(id => `${CML_PREFIX}${id}`));
    },
    hideAllDamageMarkups(exceptIds?: number[]) {
      return get().hideAllMarkups(DAMAGE_PREFIX, exceptIds?.map(id => `${DAMAGE_PREFIX}${id}`));
    },
    // Add Cml markup
    addCmlMarkup(cml: Partial<Cml>, markup: TMarkupObjects) {
      const state = get();
      const {canvas, removeCmlMarkup} = state;
      if (canvas) {
        removeCmlMarkup(cml);
        canvas.add(...Object.values(markup));
        set({
          allMarkups: {
            ...get().allMarkups,
            [`${CML_PREFIX}${cml.id!}`]: markup,
          },
        });
        //        canvas.renderAll();
        return true;
      }

      return false;
    },
    removeCmlMarkup(cml: Partial<Cml>) {
      const state = get();
      const {canvas, allMarkups} = state;
      if (canvas) {
        const markupId = `${CML_PREFIX}${cml.id!}`;
        if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
          // delete markup
          canvas?.remove(...Object.values(allMarkups[markupId]));
          set({
            allMarkups: _.omit(allMarkups, [markupId]),
          });
          return true;
        }
      }

      return false;
    },
    removeDamageMarkup(damage: Partial<Damage>) {
      const state = get();
      const {canvas, allMarkups} = state;
      if (canvas) {
        const markupId = `${DAMAGE_PREFIX}${damage.id!}`;
        if (allMarkups && Object.keys(allMarkups).includes(markupId)) {
          // delete markup
          canvas.remove(...Object.values(allMarkups[markupId]));
          set({
            allMarkups: _.omit(allMarkups, [markupId]),
          });
          return true;
        }
      }

      return false;
    },
    addDamageMarkup(damage: Partial<Damage>, markup: TMarkupObjects) {
      const state = get();
      const {canvas, removeDamageMarkup} = state;
      if (canvas) {
        removeDamageMarkup(damage);
        canvas.add(...Object.values(markup));
        set({
          allMarkups: {
            ...get().allMarkups,
            [`${DAMAGE_PREFIX}${damage.id!}`]: markup,
          },
        });
        //       canvas.renderAll();
        return true;
      }

      return false;
    },
    resetData() {
      get().canvas?.clear();
      set({
        ...initialState,
      });
    },
    setUpdateData(updateData: InspectionDrawingUncheckedUpdateInput) {
      set({updateData});
    },
    setEditIdwg(editIdwg) {
      set({editIdwg});
    },
    isSaved() {
      const state = get();
      return !(state.updateData && Object.keys(state.updateData).length > 0);
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      if (!Object.keys(state.updateData ?? {}).includes(field) && !forceCheck) {
        return false;
      }

      if (state.fieldErrors?.[field] === true) {
        return true;
      }

      switch (field) {
        case 'idwg':
          const idwg = (state.editIdwg?.idwg ?? '').trim();
          return idwg === '' || idwg.length > MAX_LENGTH_VALIDATORS.IDWG;
        case 'description':
          const description = (state.editIdwg?.description ?? '').trim();
          return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
        case 'sectorId':
          return !state.editIdwg?.sectorId;
        default:
          break;
      }

      return false;
    },
    hasError() {
      const state = get();

      return ['description', 'idwg', 'sectorId'].some((field: string) => state.hasFieldError(field, true));
    },
    setBgImg(bgImg: HTMLImageElement | HTMLCanvasElement) {
      set({bgImg});
    },
    setActiveInspectionDrawing: (activeInspectionDrawing: Partial<InspectionDrawing> | null) => set({activeInspectionDrawing, editIdwg: activeInspectionDrawing ? {...activeInspectionDrawing} : undefined, uploadFile: undefined, updateData: undefined}),
    setInspectionDrawings: (inspectionDrawings?: Partial<InspectionDrawing>[]) => set({
      inspectionDrawings,
    }),
    setBgInfos: (bgImg?: HTMLImageElement | HTMLCanvasElement | null, bgDimension?: TDimension, isCurrentBg?: boolean) => set(_state => {
      const newState: Partial<State> = {
        bgImg,
        bgDimension,
      };
      if (isCurrentBg) {
        newState.currentBgImg = bgImg;
        newState.currentBgDimension = bgDimension;
      }

      return newState;
    }),
    setCanvasInfos(canvas: fabric.Canvas | null, initialCanvasWidth: number, initialCanvasHeight: number) {
      set({canvas, initialCanvasWidth, initialCanvasHeight});
    },
    updateInspectionDrawing: (inspectionDrawing: Partial<InspectionDrawing>) => set(getIdwgUpdateState(get(), inspectionDrawing)),
    deleteInspectionDrawing: (inspectionDrawing?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawings: Partial<InspectionDrawing>[] = [
        ...(state.inspectionDrawings ?? []),
      ];
      const deletedInspectionDrawing: Partial<InspectionDrawing>|undefined|null = inspectionDrawing ?? state.activeInspectionDrawing;
      const newState: Partial<State> = {};
      if (inspectionDrawings.length > 0 && deletedInspectionDrawing) {
        newState.deleteInspectionDrawingModalOpen = false;
        if (deletedInspectionDrawing === state.activeInspectionDrawing) {
          newState.activeInspectionDrawing = null;
        }

        newState.inspectionDrawings = inspectionDrawings.filter((item: Partial<InspectionDrawing>) => item.id !== deletedInspectionDrawing!.id);
      }

      return newState;
    }),
    changeDeleteInspectionDrawingModalDisplay: (isOpen: boolean) => set({
      deleteInspectionDrawingModalOpen: isOpen,
    }),
    setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => set({
      flocToDelete,
    }),
    setGridToDelete: (gridToDelete?: Partial<Grid>) => set({
      gridToDelete,
    }),
    deleteIdwgFloc: (flocToDelete?: Partial<FunctionalLocation>, idwg?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawing: Partial<InspectionDrawing>|undefined|null = idwg ?? state.activeInspectionDrawing;
      const deletedFloc: Partial<FunctionalLocation> | undefined = {...(flocToDelete ?? state.flocToDelete)};
      const newState: Partial<State> = {};
      if (inspectionDrawing && deletedFloc) {
        if (deletedFloc.id === state.flocToDelete?.id) {
          newState.flocToDelete = undefined;
        }

        newState.idwgFlocs = state.idwgFlocs?.filter((item: Partial<IdwgFloc>) => item.functionalLocation?.id !== deletedFloc.id!);
      }

      return newState;
    }),
    deleteIdwgGrid: (gridToDelete?: Partial<Grid>, idwg?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawing: Partial<InspectionDrawing>|undefined|null = idwg ?? state.activeInspectionDrawing;
      const deletedGrid: Partial<Grid> | undefined = gridToDelete ?? state.gridToDelete;
      const newState: Partial<State> = {};
      if (inspectionDrawing && deletedGrid) {
        if (deletedGrid === state.gridToDelete) {
          newState.gridToDelete = undefined;
        }

        newState.idwgGrids = state.idwgGrids?.filter((item: Partial<IdwgGrid>) => item.grid?.id !== deletedGrid.id!);
      }

      return newState;
    }),
    deleteMarkupsById(id: string) {
      const {allMarkups, canvas} = get();
      const newState: Partial<State> = {};
      if (allMarkups && allMarkups[id]) {
        const currentMarkups = allMarkups[id];
        if (currentMarkups) {
          Object.values(currentMarkups).forEach((o: fabric.FabricObject) => {
            o.visible = false;
            canvas?.remove(o);
          });
          delete allMarkups[id];
          newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
        }
      }

      set(newState);
    },
  })),
);
type IdwgStore = ReturnType<typeof createIdwgStore>;

const stores: Record<string, IdwgStore> = {};
const getStore = (key?: string, initValues?: Partial<State>) => {
  let store: IdwgStore = stores[key ?? 'DEFAULT'];
  if (!store) {
    store = createIdwgStore(initValues);
    stores[key ?? 'DEFAULT'] = store;
  }

  return store;
};

const useIdwgStore = (key?: string, initValues?: Partial<State>) => useStore(getStore(key, initValues));
export const deleteIdwgStore = (key:string) => {
  if (stores[key]) {
    delete stores[key];
  }
};

export default useIdwgStore;
