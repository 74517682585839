
import {useLazyQuery} from '@apollo/client';
import CmlCard from '@app/components/Common/Block/Cml/CmlBlock/CmlCard';
import CmlListModal from '@app/components/Common/Block/Cml/CmlBlock/CmlListModal';
import CmlModal from '@app/components/Common/Block/Cml/CmlModal';
import IdwgCmlSelectionModal from '@app/components/Common/Block/Cml/IdwgCmlSelectionModal';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {CmlCreateModal} from '@app/components/Modal/Inspection/Cml/Create';
import {Cml, GetCmlsByIdwgIdQuery, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {CMLS_GET_BY_IDWG_ID} from '@app/graphql/requests/cmls';
import useCmlStore from '@app/stores/cml';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {searchArray} from '@app/utils/functions';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {LuList, LuMapPin, LuPlusSquare} from 'react-icons/lu';

type TCmlList = Readonly<{
  idwg: Partial<InspectionDrawing>;
}>;

/** Drawing Cml list */
export default function CmlList({idwg}: TCmlList) {
  const [filteredCmls, setFilteredCmls] = useState<Partial<Cml>[]>();
  const [filterString, setSearchInput] = useState<string>('');

  const {setCmls, cmls, fetchCmls, setFetchCmls, changeCmlsSelectionModalDisplay, cmlsSelectionDisplayed, changeLastCmlUpdated} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const {activeCml, setActiveCml} = useCmlStore();
  const [cmlListModalDisplayed, changeCmlListModalDisplay] = useState<boolean>(false);
  const [getCmlListApi] = useLazyQuery<GetCmlsByIdwgIdQuery>(CMLS_GET_BY_IDWG_ID, {
    variables: {
      idwgId: idwg!.id,
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const onCreatedOrUpdated = (item?: Partial<Cml>) => {
    if (item) {
      changeLastCmlUpdated(item);
    }

    fetchCmls?.();
  };

  useEffect(() => {
    setFetchCmls(getCmls);
  }, []);

  useEffect(() => {
    fetchCmls?.();
  }, [fetchCmls]);

  useEffect(() => {
    setFilteredCmls(
      searchArray(
        cmls ?? [],
        filterString,
        undefined,
        ['__typename'],
      ),
    );
  }, [cmls, filterString]);

  const getCmls = () => {
    getCmlListApi().then(queryResults => setCmls(queryResults.data?.cmls as Cml[] ?? []));
  };

  const handleCmlModalClose = () => {
    setActiveCml();
  };

  console.log(filteredCmls);

  return (
    <>
      <FormGroupHeader
        menuItems={[
          (
            <RadDropdownMenuItem key='manage-cml' onClick={() => changeCmlsSelectionModalDisplay(true)}>
              <LuPlusSquare className='mr-2'/>

              {t('label.manageCML')}
            </RadDropdownMenuItem>
          ),
          (
            <RadDropdownMenuItem key='display-list' onClick={() => changeCmlListModalDisplay(true)}>
              <LuList className='mr-2'/>

              {t('label.displayList')}
            </RadDropdownMenuItem>
          ),
        ]}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }>
        <div className='flex items-center'>
          <LuMapPin size={20} className='mr-2'/> {t('label.cml')}
        </div>
      </FormGroupHeader>

      <div className='mt-3'>
        <div className='flex flex-col gap-1'>
          {filteredCmls?.map(ecml => (<CmlCard key={ecml.id} hasMenuContext eventCml={ecml}/>))}
        </div>
      </div>

      {!!activeCml && activeCml.id !== OBJ_NEW_ID && <CmlModal isOpen item={activeCml!} onCreatedOrUpdated={onCreatedOrUpdated} onClose={handleCmlModalClose}/>}
      {!!activeCml && activeCml.id === OBJ_NEW_ID && <CmlCreateModal isOpen item={activeCml!} onCreated={onCreatedOrUpdated} onOpenChange={isOpen => !isOpen && handleCmlModalClose()}/>}
      {!!cmlsSelectionDisplayed && <IdwgCmlSelectionModal idwg={idwg}/>}
      {cmlListModalDisplayed && <CmlListModal open hasItems sortOrders={['asc']} items={cmls} onClose={() => changeCmlListModalDisplay(false)}/>}
    </>
  );
}
