import {RefObject, useRef} from 'react';

import {
  createMarkupEditorShapeStyleControls,
  createMarkupEditorToolbar,
  createMarkupEditorToolStyles,
  getEditorDefaults,
  PinturaDefaultImageWriterResult,
  PinturaEditorOptions,
  PinturaImageState,
} from '@pqina/pintura';
import {PinturaComponentEvents, PinturaEditor} from '@pqina/react-pintura';
import {cleanAllAnnotations, TAppAnnotation, TAppPinturaDefaultImageWriterResult} from './util';

import '@pqina/pintura/pintura.css';
import './index.css';

type TImageEditorProps = Readonly<{
  /**
   * The image editor's ref.
   *
   * Use when you need to access the editor's properties/methods/events etc. outside of this component.
   */
  editorRef?: RefObject<PinturaEditor>;

  /**
   * Object containing the image's edits' informations.
   *
   * Use this prop with `onUpdate` to make the `imageState` controlled.
   */
  imageState?: PinturaImageState;
  /**
   * Event fired when any edit is made to the image.
   *
   * Use this prop with `imageState` to make the `imageState` controlled.
   * @param imageState Object containing the image's edits' informations.
   */
  onUpdate?: (imageState: PinturaImageState) => void;
  /**
   * Event fired when the "Done" button is pressed.
   *
   * Useful if you only need to get the image's edits once as opposed to any time a change is made.
   * @param detail Object containing the editor's informations (`detail.imageState` to get the image's edits).
   */
  onProcess?: (detail: TAppPinturaDefaultImageWriterResult) => void;
} & Omit<PinturaComponentEvents, 'onProcess'> & Omit<PinturaEditorOptions, 'locale' | 'imageReader'>>;

const editorDefaults = getEditorDefaults({
  util: 'annotate',
  utils: [
    'annotate',
    'finetune',
    'crop',
    'resize',
  ],
  markupEditorToolbar: createMarkupEditorToolbar([
    'line',
    'arrow',
    'path',
    'rectangle',
    'ellipse',
    'text',
    'eraser',
  ]),
  markupEditorShapeStyleControls: {
    ...createMarkupEditorShapeStyleControls({
      strokeColor: {
        options: {
          enableInput: true,
          enableEyeDropper: true,
        },
      },
      backgroundColor: {
        options: {
          enableInput: true,
          enableEyeDropper: true,
        },
      },
      color: {
        options: {
          enableInput: true,
          enableEyeDropper: true,
        },
      },
      textOutline: {
        options: {
          enableInput: true,
          enableEyeDropper: true,
        },
      },
    }),
  },
  markupEditorToolStyles: createMarkupEditorToolStyles({
    line: {
      strokeColor: [0, 0, 0],
    },
    arrow: {
      strokeColor: [0, 0, 0],
    },
    path: {
      strokeColor: [0, 0, 0],
      backgroundColor: [0, 0, 0, 0],
    },
    rectangle: {
      strokeColor: [0, 0, 0],
      strokeWidth: '0.5%',
      backgroundColor: [0, 0, 0, 0],
    },
    ellipse: {
      strokeColor: [0, 0, 0],
      strokeWidth: '0.5%',
      backgroundColor: [0, 0, 0, 0],
    },
    text: {
      strokeColor: [0, 0, 0],
      strokeWidth: 0,
      backgroundColor: [0, 0, 0, 0],
      color: [0, 0, 0],
      fontSize: '4%',
      textOutline: ['0', [255, 255, 255]],
      disableStyle: ['lineHeight'],
    },
  }),
});

/** Pintura image editor. */
export default function ImageEditor({...props}: TImageEditorProps) {
  const editorRef = useRef<PinturaEditor>(null);

  const handleEditorLoad = () => {
    if (!editorRef || !editorRef.current || props.imageState === undefined) {
      return;
    }

    editorRef.current.editor.history.write(props.imageState);
  };

  const handleOnProcess = (detail: PinturaDefaultImageWriterResult) => {
    if (!props.onProcess) {
      return;
    }

    let appAnnotations: TAppAnnotation[] = [];

    if (detail.imageState.annotation) {
      appAnnotations = cleanAllAnnotations(detail.imageState.annotation);
    }

    const newDetail: TAppPinturaDefaultImageWriterResult = {
      ...detail,
      imageState: {
        ...detail.imageState,
        annotation: appAnnotations,
      },
    };

    props.onProcess(newDetail);
  };

  // Restore state
  // editorRef.current.editor.history.write(imageStateToRestore);

  // Clear state
  // editorRef.current.editor.history.revert();

  return (
    <PinturaEditor
      ref={props.editorRef !== undefined ? props.editorRef : editorRef}
      {...editorDefaults}
      {...props}
      onLoad={handleEditorLoad}
      onProcess={handleOnProcess}
    />
  );
}
