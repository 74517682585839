import {useLazyQuery} from '@apollo/client';
import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {FunctionalLocation, GetAllFlocViewsAutocompleteQuery} from '@app/graphql/__types__/graphql';
import {FLOC_VIEWS_GET_ALL_AUTOCOMPLETE} from '@app/graphql/requests';
import {TDataRowTreeList} from '@holis/react-ui';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export default function FlocSelectionModal({items, hasItems, ...restProps}: TSelectionConfirmModalWithoutListProps<Partial<FunctionalLocation>>) {
  const {t} = useTranslation();
  const [getFlocsApi, {data, loading, error}] = useLazyQuery<GetAllFlocViewsAutocompleteQuery>(FLOC_VIEWS_GET_ALL_AUTOCOMPLETE);
  useEffect(() => {
    if (!hasItems) {
      getFlocsApi();
    }
  }, []);

  return (
    <SelectionConfirmModal
      title={t('label.functionalLocation')}
      description={t('label.selectFloc')}
      listProps={{
        listProps: {
          isLoading: loading,
          error: error ? t('message.error.default.title') : undefined,
          data: (items ?? data?.flocViews ?? []) as TDataRowTreeList[],
          cellRenderers: {
            // integrityStatus: (data: string, rowData: unknown) => <span className='-ml-[8px] flex justify-center'>{(rowData as Partial<FunctionalLocation>).integrityStatus ? 'INTS' : ''}</span>,
          },
          columns: [
            {
              field: 'floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: !hasItems ? 'techClass_techClass' : 'techClass.techClass',
              title: t('label.techClass'),
              type: 'string',
              width: 150,
            },
            // {
            //   field: 'integrityStatus',
            //   title: t('label.integrityStatus'),
            //   type: 'string',
            //   cellRenderer: 'integrityStatus',
            //   width: 60,
            // },
          ],
        },
      }}
      {...restProps}
    />
  );
}
