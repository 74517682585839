import SimplePageSelector from '@app/components/Common/Tabs/SimplePageSelector';
import {useTranslation} from 'react-i18next';
import DrawingPage from './pages/Drawing';
import PicturePage from './pages/Picture';
import {useEventStore} from '@app/stores/event';
import ReportPage from './pages/Report';
import {EWorkpackRightSideTab} from '@app/utils/enums';
import FlocOverviewDrawing from '@app/components/Common/Block/Floc/FlocBlock/FlocOverviewDrawing';

export type RightPanelPage = Readonly<{
  visible?: boolean;
  readonly?: boolean;
}>;

export type RightPanelProps = Readonly<{
  readonly?: boolean;
}>;

export default function RightPanel({readonly}: RightPanelProps) {
  const {t} = useTranslation();
  const {activeEvent, setRightSideTabSelectedValue, rightSideTabSelectedValue} = useEventStore();
  return (
    <div className='h-full w-full flex flex-col'>

      <SimplePageSelector
        className='mt-2'
        items={[
          {label: t('label.picture'), value: EWorkpackRightSideTab.PICTURES},
          {label: t('label.drawing'), value: EWorkpackRightSideTab.DRAWINGS},
          {label: t('label.overview'), value: EWorkpackRightSideTab.OVERVIEW},
          {label: t('label.report'), value: EWorkpackRightSideTab.REPORT},
        ]}
        selectedValue={rightSideTabSelectedValue}
        onSelectedValueChanged={setRightSideTabSelectedValue}
      />
      <div className='p-4 flex-1 flex flex-col overflow-auto'>

        <PicturePage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.PICTURES} readonly={readonly}/>

        <DrawingPage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS} readonly={readonly}/>

        {activeEvent?.schedulingTag && <FlocOverviewDrawing floc={activeEvent.schedulingTag} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.OVERVIEW}/>}

        <ReportPage readonly={readonly} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.REPORT}/>

      </div>
    </div>

  );
}
