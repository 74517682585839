import {useLazyQuery} from '@apollo/client';
import NotificationNewModal from '@app/components/Common/Block/Notification/NotificationNewModal';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {GetAllNotificationsQuery, Notification} from '@app/graphql/__types__/graphql';
import {NOTIFICATIONS_GET_ALL} from '@app/graphql/requests';
import useNotificationStore from '@app/stores/notification';
import {FORMAT_DATE_EU, OBJ_NEW_ID, ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL} from '@app/utils/constants';
import {ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import {RadBadge, RadButton, RadCheckbox} from '@holis/react-ui/rad';
import moment from 'moment';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
export default function AnomalyManagementNotificationPage() {
  const {setActiveNotification, activeNotification} = useNotificationStore();
  const [notifications, setNotifications] = useState<Partial<Notification>[]>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [getNotificationsApi, {loading, error}] = useLazyQuery<GetAllNotificationsQuery>(NOTIFICATIONS_GET_ALL, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  // const handleCloseModal = () => {
  //   setActiveNotification();
  // };

  const getNotifications = () => {
    console.log('reload notifs');
    getNotificationsApi().then(queryResults => setNotifications(queryResults.data?.notifications as Partial<Notification>[]));
  };

  const openNotifDetail = (notifNumber: string) => {
    navigate(ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION_DETAIL.replace(':number', notifNumber));
  };

  const handleNewNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: t('label.autoSetValue'),
      creationDate: new Date(),
      __typename: 'Notification',
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const gridProps: TGridProps = {
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
      },
      {
        field: 'notif',
        title: t('label.notificationNumber'),
        type: 'string',
        width: 80,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 80,
      },
      {
        field: 'notifType.notifType',
        title: t('label.type'),
        type: 'string',
        width: 80,
      },
      {
        field: 'creationDate',
        title: t('label.creationDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportedBy',
        title: t('label.reportedBy'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'isTempRepair',
        title: t('label.tempRepair'),
        type: 'string',
        fieldType: 'boolean',
        cellRenderer: 'boolean',
        width: 80,
      },
      {
        field: 'ffsRequired',
        title: t('label.ffsRequired'),
        type: 'string',
        width: 80,
        cellRenderer: 'boolean',
        fieldType: 'boolean',
      },
      {
        field: 'isBelowMawt',
        title: t('label.belowMAWT'),
        type: 'string',
        width: 80,
        fieldType: 'boolean',
        cellRenderer: 'boolean',
      },
      {
        field: 'isLeak',
        title: t('label.leak'),
        type: 'string',
        width: 80,
        fieldType: 'boolean',
        cellRenderer: 'boolean',
      },
      {
        field: 'plannerGroup.code',
        title: t('label.plannerGroup'),
        type: 'string',
        width: 80,
      },
      {
        field: 'mainWorkCenter.code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        width: 80,
      },
      {
        field: 'plantWorkCenter.code',
        title: t('label.plantWorkCenter'),
        type: 'string',
        width: 80,
      },
      {
        field: 'shutdown.code',
        title: t('label.shutdown'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'jobType.code',
        title: t('label.jobType'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'ressource.code',
        title: t('label.ressource'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'repairSystem.code',
        title: t('label.repairSystem'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'detection.code',
        title: t('label.detection'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'consequence',
        title: t('label.consequence'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'severity',
        title: t('label.severity'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'notifLikelihood.likelihood',
        title: t('label.likelihood'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'notifPriority.code',
        title: t('label.priority'),
        type: 'string',
        width: 80,
      },
      {
        field: 'requiredStartDate',
        title: t('label.requiredStartDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
      },
      {
        field: 'requiredEndDate',
        title: t('label.requiredEndDate'),
        type: 'date',
        cellRenderer: 'date',
        width: 80,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'date',
        width: 80,
        cellRenderer: 'status',
      },
      {
        field: 'statusExport',
        title: t('label.statusExport'),
        type: 'date',
        width: 80,
      },
      {
        field: 'functionalLocation.floc',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'functionalLocation.description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'functionalLocation.techClass.techClass',
        title: t('label.technicalClass'),
        type: 'string',
        width: 150,
        ignoreCase: true,
      },
      {
        field: 'functionalLocation.classSece',
        title: t('label.classSece'),
        type: 'string',
        width: 150,
        ignoreCase: true,
        hidden: true,
      },
      {
        field: 'functionalLocation.sector.site.site',
        title: t('label.site'),
        type: 'string',
        width: 100,
        ignoreCase: true,
      },
      {
        field: 'functionalLocation.sector.sector',
        title: t('label.sector'),
        type: 'string',
        width: 100,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => openNotifDetail((rowData as Partial<Notification>).notif!)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      status(val: string, rowData: unknown) {
        if (!val) {
          return '';
        }

        const statusBg = (rowData as Partial<Notification>).statusNotif?.displayColor ? (
          `bg-${(rowData as Partial<Notification>).statusNotif?.displayColor}-200`
        ) : 'bg-gray-200';

        const statusFg = (rowData as Partial<Notification>).statusNotif?.displayColor ? (
          `text-${(rowData as Partial<Notification>).statusNotif?.displayColor}-700`
        ) : 'text-gray-700';

        return (
          <RadBadge variant='outline' className={`${statusBg} ${statusFg} py-1 font-normal`}>
            {t(`label.notifStatuses.${val.toLowerCase()}`)}
          </RadBadge>
        );
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      boolean: (val: boolean) => <><RadCheckbox title={t(`label.${val ? 'yes' : 'no'}`)} checked={val}/><span className='hidden'>{t(`label.${val ? 'yes' : 'no'}`)}</span></>,
    },
  };

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<Notification>[] = [
    'action',
    'notif',
    'description',
    'type.notifType',
    'status',
    'statusExport',
    'functionalLocation.floc',
  ];

  return (
    <div className='h-full'>
      <Grid
        gridName='anomalyManagement-notificationsGrid'
        queryData={{
          data: notifications ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.notification')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm bg-sky-500 text-white hover:bg-sky-600'
          onClick={handleNewNotif}
        >
          <LuPlusCircle/> {t('label.addNotification')}
        </RadButton>
      </Grid>

      {!!activeNotification && <NotificationNewModal isOpen onCreated={getNotifications} onOpenChange={isOpen => !isOpen && setActiveNotification()}/>}
    </div>
  );
}
