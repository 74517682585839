import AppNotifications from '@app/services/notification';

import {useLazyQuery, useMutation} from '@apollo/client';
import {CreateDocumentMutation, GetDocumentDetailByNumberQuery} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENTS_CREATE, DOCUMENTATION_DOCUMENTS_GET_DETAIL_BY_NUMBER} from '@app/graphql/requests';
import {useDocumentStore, ZOD_DOCUMENT_DATAS} from '@app/stores/document';
import {getFileNameWithoutExtension, setObjValueByPath} from '@app/utils/functions';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import {useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';
import Footer from '../../Scheduling/Plan/components/Footer';
import SingleFormModal from '../../SingleFormModal';
import Form from './components/Form';
import {useDataStore} from '@app/stores/data';
import {useLayoutStore} from '@app/stores/layout';
import WarningConfirmModal from '../../Confirm/Warning';
import {useNavigate} from 'react-router-dom';
import {ROUTE_DOCUMENTATION_DOC_DETAIL} from '@app/utils/constants';

type TDocumentModal = {
  readonly isOpen: boolean;
  readonly onOpenChange: (isOpen: boolean) => void;
};

export default function DocumentModal(props: TDocumentModal) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [confirmDocNameExistsModalDisplayed, changeConfirmDocNameExistsModalDisplay] = useState<boolean>(false);
  const {startLoading, stopLoading} = useLayoutStore();
  const {editDoc, setEditDoc, updateDataField, updateData, updateFieldError, fetchDocs, uploadFile} = useDocumentStore();
  const [createDocumentApi] = useMutation<CreateDocumentMutation>(DOCUMENTATION_DOCUMENTS_CREATE);
  const {uploadDownloadService} = useDataStore();
  const [getDocumentByNumberApi] = useLazyQuery<GetDocumentDetailByNumberQuery>(DOCUMENTATION_DOCUMENTS_GET_DETAIL_BY_NUMBER);

  const zodFormObject = z.object(ZOD_DOCUMENT_DATAS(t));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {
      resolver: zodResolver(zodFormObject), mode: 'onSubmit', defaultValues: {
        revision: '',
      },
    });
  const htmlForm = useRef<HTMLFormElement>(null);

  const handleOverrideDoc = (confirmed: boolean) => {
    if (confirmed) {
      handleCreateDocument(true);
    } else {
      updateDocument();
    }
  };

  const checkNumberExists = async () : Promise<boolean> => {
    const result = await getDocumentByNumberApi({
      variables: {
        number: updateData?.document,
      },
    });
    return !!result.data?.document;
  };

  // Update document value in store (will update updateData)
  const updateDocumentDataValue = (field: string, value: unknown) => {
    if (field === 'document') {
      value = (value as string).toUpperCase();
    }

    const editedDocument = {...editDoc};
    setObjValueByPath(editedDocument, field, value);
    updateDataField(field, value);

    setEditDoc(editedDocument);
  };

  // Watch form value changes
  form.watch((datas, {name, type}) => {
    if (type === 'change') {
      updateDocumentDataValue(name!, datas[name!]);
    }
  });

  // Handle document creation error.
  const handleDocumentCreateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title');
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`document`)')) {
        if (AppNotifications.timeoutId) {
          clearTimeout(AppNotifications.timeoutId);
        }

        errorMessage = t('message.error.unique.documentation.document.reference');
        updateFieldError('document', true);
        form.setError('document', {
          message: errorMessage,
        });
        return;
      }
    }

    AppNotifications.error(errorMessage);
  };

  // Handle actual document creation.
  const handleCreateDocument = async (updateIfExists = false) => {
    // // Check if any field in updateData has an error.
    // if (hasError()) {
    //   AppNotifications.error(t('message.error.requiredFields'));
    //   return;
    // }

    // Check number exists
    const numberExists = await checkNumberExists();
    if (numberExists) {
      if (AppNotifications.timeoutId) {
        clearTimeout(AppNotifications.timeoutId);
      }

      updateFieldError('document', true);
      form.setError('document', {
        message: t('message.error.unique.documentation.document.reference'),
      });
      return;
    }

    if (uploadFile) {
      startLoading();
      uploadDownloadService!.uploadFile(uploadFile, {
        updateIfExists: updateIfExists ? 1 : 0,
      }).then(response => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data);
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText);
        }

        updateDocument();
      }).catch((err: Error) => {
        console.log(err);
        let errorMessage = t('message.error.uploadDocumentationDoc');
        if (typeof err?.message === 'string') {
          errorMessage = err.message;
          if (err.message.includes(`File ${getFileNameWithoutExtension(uploadFile.name)} already exists`)) {
            errorMessage = t('message.error.unique.file.doc.docName');
            updateFieldError('docName', true);
            changeConfirmDocNameExistsModalDisplay(true);
          }
        }

        AppNotifications.error(errorMessage);
      }).finally(stopLoading);
    } else {
      updateDocument();
    }
  };

  const updateDocument = () => {
    startLoading();
    createDocumentApi({variables: {data: updateData}}).then(() => {
      AppNotifications.success(t('message.success.documentCreated'));
      fetchDocs?.();
      props.onOpenChange(false);
      navigate(ROUTE_DOCUMENTATION_DOC_DETAIL.replace(/:number/, editDoc!.document!));
    }).catch((err: Error) => {
      handleDocumentCreateError(err);
    }).finally(stopLoading);
  };

  return (
    <SingleFormModal
      isOpen={props.isOpen}
      title={t('label.documentCreation')}
      description={t('label.itemCreateDescription')}
      footer={<Footer onSaveClick={() => htmlForm.current?.requestSubmit()}/>}
      onOpenChange={props.onOpenChange}
    >
      <RadForm {...form}>
        <form ref={htmlForm} onSubmit={form.handleSubmit(() => handleCreateDocument(false))}>
          <Form/>
        </form>
      </RadForm>
      <WarningConfirmModal closeOnConfirm closeOnCancel cancelButtonLabel={t('label.no')} confirmButtonLabel={t('label.yes')} className='min-w-[auto] min-h-[150px] w-[600px]' open={confirmDocNameExistsModalDisplayed} description={t('message.questions.fileNameExistsWantToOverrideDoc')} onConfirm={() => handleOverrideDoc(true)} onClose={() => changeConfirmDocNameExistsModalDisplay(false)} onCancel={() => handleOverrideDoc(false)}/>
    </SingleFormModal>
  );
}
