import React, {PropsWithChildren} from 'react';

import {GridPageContent, TDataRowTreeList, TTreeList} from '@holis/react-ui';
import {addRadSon} from '@holis/react-ui/rad';

import './grid.css';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useTranslation} from 'react-i18next';

export type TGridProps<TRow extends TDataRowTreeList = TDataRowTreeList> = Partial<TTreeList<TRow>>;
export type TQueryData = {
  data: unknown[];
  loading: boolean;
  error?: Error | null;
};

type TProps<TRow extends TDataRowTreeList = TDataRowTreeList> = {
  /** Unique name for the session/local storage of this specific grid instance. */
  readonly gridName: string;
  readonly queryData: TQueryData;
  readonly gridProps: TGridProps<TRow>;
  readonly title?: React.ReactNode;
  readonly columnsAlwaysVisible?: TNestedKeys<TRow>[];
} & PropsWithChildren;

export function Grid(props: TProps) {
  const {t} = useTranslation();
  const onSaveLayout = () => {
    addRadSon.success(t('message.success.layoutSaved'));
  };

  const onResetLayout = () => {
    addRadSon.success(t('message.success.defaultLayoutRestored'));
  };

  return (
    <GridPageContent
      disableFooter
      defaultColsFit='container'
      gridName={props.gridName}
      queryData={props.queryData}
      gridProps={{...props.gridProps, showFooter: true}}
      actions={props.children}
      title={props.title}
      columnsAlwaysVisible={props.columnsAlwaysVisible}
      onSaveLayout={onSaveLayout}
      onResetLayout={onResetLayout}
    />
  );
}
