import {useTranslation} from 'react-i18next';
import {renderCodeAndDescription} from '@app/utils/functions';
import React, {InputHTMLAttributes} from 'react';
import {TFieldsBlock, TMaybeCodeDescriptionDatas} from '@app/types/app';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import {Notification, GetAllRefNotifAdditionalDataQuery, RefNotifAdditionalData, GetAllRefFlocCatalogsQuery, GetAllRefNotifCatalogsQuery, RefFlocCatalogs, RefNotifCatalog} from '@app/graphql/__types__/graphql';
import {QueryResult, useQuery} from '@apollo/client';
import {NOTIFICATION_CATALOGS_GET_MANY, NOTIFICATION_REF_ADDITIONAL_DATA_GET_MANY} from '@app/graphql/requests';
import _ from 'lodash';
import useNotificationStore, {MAX_LENGTH_VALIDATORS} from '@app/stores/notification';
import {EFieldType, EFLOC_CATALOGS_CATEGORY, ENOTIFICATION_ADDITIONAL_DATA_CATEGORY, ENOTIFICATION_CATALOGS_CATEGORY} from '@app/utils/enums';
import {LuClipboardList} from 'react-icons/lu';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';

type TSpecificData = Readonly<{
  notification: Partial<Notification>
}>

export default function SpecificData({notification}: TSpecificData) {
  const {t} = useTranslation();
  const {editNotification, hasFieldError, renderAutocomplete, handleFieldChange, setObjectGroup, setDamageGroup, setCauseGroup, objectGroup, damageGroup, causeGroup, detectionGroup, setDetectionGroup, setSubmitRequested} = useNotificationStore();
  const refNotifAdditionalDataResult = useQuery<GetAllRefNotifAdditionalDataQuery>(NOTIFICATION_REF_ADDITIONAL_DATA_GET_MANY, {
    variables: {
      orderBy: [
        {codeGroup: 'asc'},
        {shortTextCode: {sort: 'asc'}},
      ],
    },
  });
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY);
  const refNotifCatalogResult = useQuery<GetAllRefNotifCatalogsQuery>(NOTIFICATION_CATALOGS_GET_MANY, {
    variables: {
      orderBy: [
        {code: {sort: 'asc'}},
        {description: {sort: 'asc'}},
      ],
    },
  });

  const isFormDisabled = false;

  const BLOCK_MISCELLANEOUS: TFieldsBlock = {
    title: 'label.miscellaneous',
    fields: [
      {
        label: 'label.shutdown',
        field: 'shutdownId',
        initialValue: renderCodeAndDescription(notification!.shutdown as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.shutdown as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.shutdown,
        foreignField: 'shutdown',
        dbValue: notification!.shutdownId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.SHUTDOWN) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
      {
        label: 'label.jobType',
        field: 'jobTypeId',
        initialValue: renderCodeAndDescription(notification!.jobType as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.jobType as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.jobType,
        foreignField: 'jobType',
        dbValue: notification!.jobTypeId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifCatalogsQuery>)?.data?.refNotifCatalogs?.filter((item: Partial<RefNotifCatalog>) => item.category === ENOTIFICATION_CATALOGS_CATEGORY.JOB_TYPE) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
      {
        label: 'label.ressource',
        field: 'ressourceId',
        initialValue: renderCodeAndDescription(notification!.ressource as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.ressource as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.ressource,
        foreignField: 'ressource',
        dbValue: notification!.ressourceId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifCatalogsQuery>)?.data?.refNotifCatalogs?.filter((item: Partial<RefNotifCatalog>) => item.category === ENOTIFICATION_CATALOGS_CATEGORY.RESSOURCE) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
      {
        label: 'label.repairSystem',
        field: 'repairSystemId',
        initialValue: renderCodeAndDescription(notification!.repairSystem as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editNotification!.repairSystem as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.repairSystem,
        foreignField: 'repairSystem',
        dbValue: notification!.repairSystemId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifCatalogResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifCatalogsQuery>)?.data?.refNotifCatalogs?.filter((item: Partial<RefNotifCatalog>) => item.category === ENOTIFICATION_CATALOGS_CATEGORY.REPAIR_SYSTEM) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
    ],
    fieldsClassName: 'w-full flex flex-row flex-wrap',
  };

  const BLOCK_DETECTION: TFieldsBlock = {
    title: 'label.detection',
    fields: [
      {
        label: 'label.detectionGroup',
        field: 'detectionGroup',
        itemIdField: 'codeGroup',
        listProps: {
          titleClassName: t('label.selectDetectionGroup'),
        },
        initialValue: renderCodeAndDescription({description: detectionGroup?.shortText, code: detectionGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: detectionGroup?.shortText, code: detectionGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        foreignObject: detectionGroup,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => _.uniqBy((result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.DETECT_METHOD).map((item: Partial<RefNotifAdditionalData>) => ({codeGroup: item.codeGroup, shortText: item.shortText})) ?? [], 'codeGroup'),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as RefNotifAdditionalData)?.shortText, code: (field as RefNotifAdditionalData)?.codeGroup} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        onSelect(item) {
          setDetectionGroup(item as Partial<RefNotifAdditionalData>);
        },
      },
      {
        label: 'label.detectionMethod',
        field: 'detectionId',
        listProps: {
          titleClassName: t('label.selectDetectionMethod'),
        },
        initialValue: renderCodeAndDescription({description: notification!.detection?.shortTextCode, code: notification!.detection?.code} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: editNotification!.detection?.shortTextCode, code: editNotification!.detection?.code} as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.detection,
        foreignField: 'detection',
        dbValue: notification!.detectionId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.DETECT_METHOD && (!detectionGroup || item.codeGroup === detectionGroup?.codeGroup)) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as RefNotifAdditionalData)?.shortTextCode, code: (field as RefNotifAdditionalData)?.code} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
    ],
  };

  const BLOCK_SAP_SPECIFIC_DATA: TFieldsBlock = {
    title: 'label.sapSpecificData',
    fields: [
      {
        label: 'label.objectGroup',
        field: 'objectGroup',
        itemIdField: 'codeGroup',
        listProps: {
          titleClassName: t('label.selectObjectGroup'),
        },
        initialValue: renderCodeAndDescription({description: objectGroup?.shortText, code: objectGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: objectGroup?.shortText, code: objectGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        isDisabled: isFormDisabled,
        foreignObject: objectGroup,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => _.uniqBy((result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.OBJECT).map((item: Partial<RefNotifAdditionalData>) => ({codeGroup: item.codeGroup, shortText: item.shortText})) ?? [], 'codeGroup'),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortText, code: (field as Partial<RefNotifAdditionalData>)?.codeGroup} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        onSelect(item) {
          setObjectGroup(item as Partial<RefNotifAdditionalData>);
        },
      },
      {
        label: 'label.object',
        field: 'objectId',
        listProps: {
          titleClassName: t('label.selectOBject'),
        },
        initialValue: renderCodeAndDescription({description: notification!.object?.shortTextCode, code: notification!.object?.code} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: editNotification!.object?.shortTextCode, code: editNotification!.object?.code} as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.object,
        foreignField: 'object',
        dbValue: notification!.objectId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.OBJECT && (!objectGroup || item.codeGroup === objectGroup?.codeGroup)) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortTextCode, code: (field as Partial<RefNotifAdditionalData>)?.code} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.damageGroup',
        field: 'damageGroup',
        itemIdField: 'codeGroup',
        foreignObject: damageGroup,
        listProps: {
          titleClassName: t('label.selectDamageGroup'),
        },
        initialValue: renderCodeAndDescription({description: damageGroup?.shortText, code: damageGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: damageGroup?.shortText, code: damageGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => _.uniqBy((result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.DAMAGE).map((item: Partial<RefNotifAdditionalData>) => ({codeGroup: item.codeGroup, shortText: item.shortText})) ?? [], 'codeGroup'),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortText, code: (field as Partial<RefNotifAdditionalData>)?.codeGroup} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        onSelect(item) {
          setDamageGroup(item as Partial<RefNotifAdditionalData>);
        },
      },
      {
        label: 'label.damage',
        field: 'damageId',
        listProps: {
          titleClassName: t('label.selectDamage'),
        },
        initialValue: renderCodeAndDescription({description: notification!.damage?.shortTextCode, code: notification!.damage?.code} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: editNotification!.damage?.shortTextCode, code: editNotification!.damage?.code} as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.damage,
        foreignField: 'damage',
        dbValue: notification!.damageId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.DAMAGE && (!damageGroup || item.codeGroup === damageGroup?.codeGroup)) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortTextCode, code: (field as Partial<RefNotifAdditionalData>)?.code} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.damageText',
        field: 'damageText',
        fieldType: EFieldType.text,
        hasError: hasFieldError('damageText'),
        className: 'flex w-full',
        initialValue: notification!.damageText,
        value: editNotification?.damageText,
        isDisabled: isFormDisabled,
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.DAMAGE_TEXT,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.causeGroup',
        field: 'causeGroup',
        itemIdField: 'codeGroup',
        foreignObject: causeGroup,
        listProps: {
          titleClassName: t('label.selectCauseGroup'),
        },
        initialValue: renderCodeAndDescription({description: causeGroup?.shortText, code: causeGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: causeGroup?.shortText, code: causeGroup?.codeGroup} as TMaybeCodeDescriptionDatas),
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => _.uniqBy((result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.CAUSE).map((item: Partial<RefNotifAdditionalData>) => ({codeGroup: item.codeGroup, shortText: item.shortText})) ?? [], 'codeGroup'),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortText, code: (field as Partial<RefNotifAdditionalData>)?.codeGroup} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
        onSelect(item) {
          setCauseGroup(item as Partial<RefNotifAdditionalData>);
        },
      },
      {
        label: 'label.cause',
        field: 'causeId',
        listProps: {
          titleClassName: t('label.selectCause'),
        },
        initialValue: renderCodeAndDescription({description: notification!.cause?.shortTextCode, code: notification!.cause?.code} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: editNotification!.cause?.shortTextCode, code: editNotification!.cause?.code} as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.cause,
        foreignField: 'cause',
        dbValue: notification!.causeId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.CAUSE && (!causeGroup || item.codeGroup === causeGroup?.codeGroup)) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortTextCode, code: (field as Partial<RefNotifAdditionalData>)?.code} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
      {
        label: 'label.causeText',
        field: 'causeText',
        fieldType: EFieldType.text,
        hasError: hasFieldError('causeText'),
        className: 'flex w-full',
        initialValue: notification!.causeText,
        value: editNotification?.causeText,
        isDisabled: isFormDisabled,
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.CAUSE_TEXT,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.effect',
        field: 'effectId',
        className: 'w-full',
        initialValue: renderCodeAndDescription({description: notification!.effect?.shortTextCode, code: notification!.effect?.code} as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription({description: editNotification!.effect?.shortTextCode, code: editNotification!.effect?.code} as TMaybeCodeDescriptionDatas),
        foreignObject: editNotification?.effect,
        foreignField: 'effect',
        dbValue: notification!.effectId,
        isDisabled: isFormDisabled,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refNotifAdditionalDataResult,
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefNotifAdditionalDataQuery>)?.data?.findManyRefNotifAdditionalData?.filter((item: Partial<RefNotifAdditionalData>) => item.category === ENOTIFICATION_ADDITIONAL_DATA_CATEGORY.EFFECT) ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefNotifAdditionalData>)?.shortTextCode, code: (field as Partial<RefNotifAdditionalData>)?.code} as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
    ],
    fieldsClassName: 'w-full mt-2 flex flex-row flex-wrap',
  };
  const fieldBlocks = [BLOCK_MISCELLANEOUS, BLOCK_DETECTION, BLOCK_SAP_SPECIFIC_DATA];
  return (
    <FormFieldsBlock
      key='specific-data'
      isFormContext
      blockTitleClassName='text-primary1' className='text-gray-700' fieldsBlocks={fieldBlocks}
      prefixNode={<FormGroupHeader>
        <div className='flex items-center font-bold'>
          <LuClipboardList size={20} className='mr-2'/> {t('label.specificData')}
        </div>
      </FormGroupHeader>} onFieldChange={handleFieldChange} onFieldBlur={() => setSubmitRequested(true)}/>
  );
}
