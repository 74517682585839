import {useTranslation} from 'react-i18next';
import {Plan} from '@app/graphql/__types__/graphql';
import PlanInfo from './PlanInfo';
import ObjectTaskList from './ObjectList';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import FormHorizontalSeparator from '@app/components/Common/Form/FormHorizontalSeparator';
import {RadButton} from '@holis/react-ui/rad';
import {LuPlusSquare, LuRefreshCcw} from 'react-icons/lu';
import usePlanStore from '@app/stores/plan';

type TLeftSide = Readonly<{
  plan: Partial<Plan>;
}>;

export default function LeftSide({plan}: TLeftSide) {
  const {t} = useTranslation();
  const {setFlocsSelectionDisplayed, flocs} = usePlanStore();

  return (
    <div className='h-full w-full p-4 overflow-auto'>
      <FormGroupHeader>{t('label.header')}</FormGroupHeader>
      <PlanInfo plan={plan}/>

      <FormHorizontalSeparator/>
      <FormGroupHeader
        actions={<>
          <RadButton variant='outline' className='mr-2'><LuRefreshCcw className='mr-2'/> {t('label.synchChildren')}</RadButton>
          <RadButton variant='outline' onClick={() => setFlocsSelectionDisplayed(true)}><LuPlusSquare className='mr-2'/> {t('label.addFloc')}</RadButton>
        </>}
      >{t('label.objectList')} ({flocs?.length ?? 0})</FormGroupHeader>
      <ObjectTaskList plan={plan}/>
    </div>
  );
}
