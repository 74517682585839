import {gql} from '@apollo/client';
import {DAMAGES_COMMON_FIELDS, INSPECTION_DRAWINGS_COMMON_FIELDS} from '../fragments';

export const DAMAGES_GET_ALL = gql`
${DAMAGES_COMMON_FIELDS}
${INSPECTION_DRAWINGS_COMMON_FIELDS}
query GetAllDamages($orderBy: [DamageOrderByWithRelationInput!]) {
  damages (orderBy: $orderBy) {
    ...DamageCommonFields
    inspectionDrawing {
      ...InspectionDrawingCommonFields
    }
  }
}`;

export const DAMAGES_GET_BY_IDWG_ID = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesByIdwgId($idwgId: Int!, $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {idwgId: {equals: $idwgId}}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_BY_FLOC_ID = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesByFlocId($flocId: Int!, $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {flocId: {equals: $flocId}}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_BY_NOTIF_ID = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesByNotifId($notifId: Int!, $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {notificationDamages: {some: {notifId: {equals: $notifId}}}}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_BY_FLOC_IDS = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesByFlocIds($flocIds: [Int!], $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {flocId: {in: $flocIds}}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_HAVING_EVENT_BY_FLOC_IDS = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesHavingEventByFlocIds($flocIds: [Int!], $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {flocId: {in: $flocIds}, eventDamages: {
      some: {
        wrkoId: {
          not: null
        }
      }
    }}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_BY_FLOC_IDS_AND_EVENT = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamagesByFlocIdsAndEvent($flocIds: [Int!], $evtId: Int!, $orderBy: [DamageOrderByWithRelationInput!]) {
  damages(where: {flocId: {in: $flocIds}, eventDamages: {
      some: {
        wrkoId: {
          equals: $evtId
        }
      }
    }}, orderBy: $orderBy){
    ...DamageCommonFields
  }
}`;

export const DAMAGES_CREATE = gql`
${DAMAGES_COMMON_FIELDS}
mutation createDamage($data: DamageUncheckedCreateInput!) {
  createOneDamage(data: $data) {
    ...DamageCommonFields
  }
}`;

export const DAMAGES_GET_DETAIL_BY_ID = gql`
${DAMAGES_COMMON_FIELDS}
query GetDamageDetailById($id: Int!) {
  damage(where: {id: $id}) {
    ...DamageCommonFields
  }
}`;

export const DAMAGES_UPDATE_BY_ID = gql`
${DAMAGES_COMMON_FIELDS}
mutation UpdateDamageById($id: Int!, $data: DamageUncheckedUpdateInput!) {
  updateOneDamage(data: $data, where: {id: $id}) {
    ...DamageCommonFields
  }
}`;

export const DAMAGES_DELETE_BY_ID = gql`
mutation DeleteDamageById($id: Int!) {
  deleteOneDamage(where: {id: $id}) {
    id
  }
}`;

export const DAMAGES_UPDATE_IDWG_MANY = gql`
mutation UpdateIdwgDamages($ids: [Int!], $idwg: Int) {
  updateManyDamage(data: {idwgId: {set: $idwg}}, where: {id: {in: $ids}}) {
    count
  }
}`;

export const DAMAGES_GET_COUNTER = gql`
query GetDamagesCounter {
  aggregateDamage {
    _count {
      id
    }
  }
}
`;
