import {useQuery} from '@apollo/client';
import AppCombobox from '@app/components/Common/Form/AppCombobox';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {AppListItem} from '@app/components/Common/Form/list-item';
import {FunctionalLocation, GetAllFlocsAutocompleteQuery, GetAllRefFlocCatalogsQuery, GetAllRefPlanStrategiesQuery, GetAllRefPlanTypesQuery, RefFlocCatalogs, RefPlanStrategy, RefPlanType} from '@app/graphql/__types__/graphql';
import {SCHEDULING_REF_PLAN_TYPES_GET_MANY, SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY, FLOCS_GET_ALL_AUTOCOMPLETE} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import {EFLOC_CATALOGS_CATEGORY} from '@app/utils/enums';
import {RadInput, RadDatePicker, RadTextarea, RadFormField} from '@holis/react-ui/rad';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function Form() {
  const {t} = useTranslation();

  const [types, setTypes] = useState<AppListItem[]>([]);
  const [strategies, setStrategies] = useState<AppListItem[]>([]);
  const [flocs, setFlocs] = useState<AppListItem<FunctionalLocation>[]>([]);
  const [plannerGroups, setPlannerGroups] = useState<AppListItem[]>([]);
  const [mainWorkCenters, setMainWorkCenters] = useState<AppListItem[]>([]);
  const [selectedFloc, setSelectedFloc] = useState<FunctionalLocation>();

  const {loading: typesLoading} = useQuery<GetAllRefPlanTypesQuery>(SCHEDULING_REF_PLAN_TYPES_GET_MANY, {
    onCompleted(data) {
      setTypes(data.refPlanTypes.map((type: Partial<RefPlanType>) => ({
        label: `[${type.type}] - ${type.description}`,
        value: type.id,
      })));
    },
  });
  const {loading: strategiesLoading} = useQuery<GetAllRefPlanStrategiesQuery>(SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY, {
    onCompleted(data) {
      setStrategies(data.refPlanStrategies.map((strategy: Partial<RefPlanStrategy>) => ({
        label: `[${strategy.strategy}] - ${strategy.description}`,
        value: strategy.id,
      })));
    },
  });
  const {loading: flocsLoading} = useQuery<GetAllFlocsAutocompleteQuery>(FLOCS_GET_ALL_AUTOCOMPLETE, {
    onCompleted(data) {
      setFlocs(data.functionalLocations.map((floc: Partial<FunctionalLocation>) => ({
        label: `[${floc.floc}] - ${floc.description?.replace('"', '\'\'')}`,
        value: floc.id,
        item: floc as FunctionalLocation,
      })));
    },
  });
  useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setPlannerGroups(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP).map((plannerGroup: Partial<RefFlocCatalogs>) => ({
        label: `[${plannerGroup.code}] - ${plannerGroup.description}`,
        value: plannerGroup.id,
      })));

      setMainWorkCenters(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER).map((mainWorkCenter: Partial<RefFlocCatalogs>) => ({
        label: `[${mainWorkCenter.code}] - ${mainWorkCenter.description}`,
        value: mainWorkCenter.id,
      })));
    },
  });

  return (
    <div className='flex flex-col gap-6 pl-0.5 pr-4 pb-0.5'>
      {/* Identification */}
      <div className='flex flex-col gap-4'>

        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>

        <div className='grid grid-cols-2 gap-2'>
          {/* Plan number */}
          <RadFormField
            name='plan'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.planNumber')}>
                  <RadInput
                    placeholder='SDF'
                    {...field}/>
                </InputLabelWrapper>
              )
            }
          />

          {/* Type */}
          <RadFormField
            name='typeId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.type')}>
                  <AppCombobox
                    items={types}
                    placeholder={typesLoading ? t('label.loading') : t('label.selectType')}
                    {...field}
                  />
                </InputLabelWrapper>
              )
            }/>

          {/* Description */}
          <RadFormField
            name='description'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')}>
                  <RadInput
                    placeholder={t('label.description')}
                    {...field}
                    onChange={e => {
                      field.onChange(e);
                    }}/>
                </InputLabelWrapper>
              )
            }/>

          {/* Strategy */}
          <RadFormField
            name='strategyId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.strategy')}>
                  <AppCombobox
                    items={strategies}
                    placeholder={strategiesLoading ? t('label.loading') : t('label.selectStrategy')}
                    {...field}
                  />
                </InputLabelWrapper>
              )
            }
          />

          {/* Start date */}
          <RadFormField
            name='startDate'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.startDate')}>
                  <RadDatePicker {...field} placeholder={t('label.selectStartDate')} alignCalendar='start' onValueChange={date => field.onChange(date)}/>
                </InputLabelWrapper>
              )
            }/>
        </div>
      </div>

      {/* Scheduling tag */}
      <div className='flex flex-col gap-4'>
        <FormGroupHeader>
          {t('label.schedulingTag')}
        </FormGroupHeader>

        <div className='flex gap-2'>
          {/* Functional location */}
          <RadFormField
            name='flocId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.functionalLocation')} className='w-[350px]'>
                  <AppCombobox<FunctionalLocation>
                    items={flocs}
                    popOverWidth={600}
                    placeholder={t('label.selectFloc')}
                    loading={flocsLoading}
                    onSelectedItemChanged={item => setSelectedFloc(item?.item)}
                    {...field}
                  />
                </InputLabelWrapper>
              )
            }/>

          {/* Floc description */}
          <InputLabelWrapper label={t('label.description')} className='flex-1'>
            <RadInput disabled value={selectedFloc?.description || '-'}/>
          </InputLabelWrapper>

          {/* Floc technical class */}
          <InputLabelWrapper label={t('label.technicalClass')}>
            <RadInput disabled value={selectedFloc?.techClass.techClass || '-'}/>
          </InputLabelWrapper>
        </div>
      </div>

      {/* Responsabilities */}
      <div className='flex flex-col gap-4'>
        <FormGroupHeader>
          {t('label.responsabilities')}
        </FormGroupHeader>

        <div className='flex gap-2'>
          {/* Planner group */}
          <RadFormField
            name='plannerGroupId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.plannerGroup')} className='w-full'>
                  <AppCombobox
                    {...field}
                    items={plannerGroups}
                    placeholder={t('label.selectPlannerGroup')}
                  />
                </InputLabelWrapper>
              )
            }/>

          {/* Main workcenter */}
          <RadFormField
            name='mainWorkCenterId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.mainWorkCenter')} className='w-full'>
                  <AppCombobox
                    {...field}
                    items={mainWorkCenters}
                    placeholder={t('label.selectMainWorkCenter')}
                  />
                </InputLabelWrapper>
              )
            }/>
        </div>
      </div>

      {/* Notes */}
      <div className='flex flex-col gap-4'>
        <FormGroupHeader>
          {t('label.longDescription')}
        </FormGroupHeader>
        <RadFormField
          name='notes'
          render={
            ({field, fieldState}) => (
              <InputLabelWrapper fieldState={fieldState} className='w-full'>
                <RadTextarea {...field} placeholder={t('label.longDescription')}/>
              </InputLabelWrapper>
            )
          }/>
      </div>
    </div>
  );
}
