import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {Document} from '@app/graphql/__types__/graphql';
import {TDataRowTreeList} from '@holis/react-ui';
import {useTranslation} from 'react-i18next';

export default function DocumentSelectionModal({items, ...restProps}: TSelectionConfirmModalWithoutListProps<Partial<Document>>) {
  const {t} = useTranslation();

  return (
    <SelectionConfirmModal<Partial<Document>>
      title={t('label.document')}
      description={t('label.selectDocumentDescription')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'document',
              title: t('label.document'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'docName',
              title: t('label.docName'),
              type: 'string',
              width: 100,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
