import {Event, GenerateEventWorkpackByIdMutation, UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID, WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import React, {useEffect, useMemo} from 'react';
import {useLayoutStore} from '@app/stores/layout';
import Separator from '@app/components/Common/Separator';
import {useEventStore} from '@app/stores/event';
import {useHolisAuth} from '@holis/auth-client-react';
import {EventWorkflowAction, getEventAvailableActions, WorkflowActionDirection} from '@app/utils/functions/workflows';
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter';
import {RadBadge, RadButton, RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useLocation, useNavigate} from 'react-router-dom';
import AppTooltip from '@app/components/Common/Tooltip';
import {LuXCircle, LuCheckCircle} from 'react-icons/lu';
import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu';
type TFooter = Readonly<{
  event: Partial<Event>;
  ref?: React.RefObject<HTMLDivElement>;
  readonly?: boolean;
  canValidate: boolean;
}>

export default function Footer({event, readonly, canValidate}: TFooter) {
  const {startLoading, stopLoading} = useLayoutStore();
  const navigate = useNavigate();
  const location = useLocation();
  const workflowBtnContainerRef = React.createRef<HTMLButtonElement>();
  const [generateWorkpackById] = useMutation<GenerateEventWorkpackByIdMutation>(WORKORDER_EVENTS_GENERATE_WORKPACK_BY_ID);
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const {user} = useHolisAuth();
  const {t, i18n} = useTranslation();
  const {getGenerateWorkpackBtnDisabledError, activeEvent, eventTasks, eventFlocs, updateEvent, createEventWorkflowFunc, setAssignModalOpened, setActiveEvent, setActionConfirmModalOpened: setConfirmModalOpened} = useEventStore();
  const generateWorkpackBtnDisabledError = useMemo(() => getGenerateWorkpackBtnDisabledError(), [activeEvent, eventTasks, eventFlocs]);

  const generateButtonErrorToolTipMessage = useMemo(() => {
    if (generateWorkpackBtnDisabledError) {
      return t(`message.error.generateWorkpackBtnDisabled.${generateWorkpackBtnDisabledError}`);
    }
  }, [generateWorkpackBtnDisabledError]);

  const generateWorkpackHandler = (action: EventWorkflowAction) => generateWorkpackById({variables: {id: event.id!}}).then(res => {
    if (res.data?.generateWorkpack === 0) {
      createWorkflow(action).then(() => {
        localStorage.setItem('workpackGenerated', '1');
        navigate(0);
      });
      return;
    }

    let message = t('message.error.workpackGenerationFailed');
    if (i18n.exists(`message.error.generateWorkpack.${res.data?.generateWorkpack}`)) {
      message = t(`message.error.generateWorkpack.${res.data?.generateWorkpack}`);
    }

    throw new Error(message, {
      cause: 'generate_workpack_failed',
    });
  }).catch((err: Error) => {
    let errorMessage: string = t('message.error.default.title');
    if (err.cause === 'generate_workpack_failed') {
      errorMessage = err.message;
    }

    AppNotifications.error(errorMessage);
  });

  const updateEventStatus = async (status: EWORKORDER_EVENT_STATUS) => {
    startLoading();
    let queryResult;
    try {
      queryResult = await updateEventByIdApi({
        variables: {
          id: event.id!,
          data: {
            status: {
              set: status,
            },
            // if unassign, set inspectorId to null
            inspectorId: status === EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN
              ? {
                set: null,
              } : undefined,
          },
        },
      });
      AppNotifications.success(t('message.success.eventUpdated'));
      const newEvent = {...(queryResult!.data?.updateOneEvent ?? {})} as Partial<Event>;

      updateEvent(newEvent);
    } catch {
      AppNotifications.error(t('message.error.default.title'));
    } finally {
      stopLoading();
    }
  };

  const promptForConfirmation = async (action: EventWorkflowAction) => new Promise<{cancel: boolean, comment?: string}>(resolve => {
    setConfirmModalOpened(true, action.key, (cancel: boolean, comment?: string) => {
      resolve({cancel, comment});
    });
  });

  const createWorkflow = async (action: EventWorkflowAction, comment?: string) => createEventWorkflowFunc?.({
    variables: {
      data: {
        userLogin: user?.username,
        actionDate: new Date(),
        status: `${action.from}|${action.to}`,
        wrkoId: event.id!,
        comment,
        description: t(`label.eventActions.descriptions.${action.key}`),
      },
    },
  });

  const onWorkpackAction = async (action: EventWorkflowAction) => {
    let workflowComment;

    if (action.confirm
      && !['assign_workpack', 'reassign_workpack'].includes(action.key) // assign wrko confirm modal appears after assign prompt
    ) {
      const r = await promptForConfirmation(action);
      if (r.cancel) {
        return;
      }

      workflowComment = r.comment;
    }

    switch (action.key) {
      case 'generation_of_workpack':
        await generateWorkpackHandler(action);
        return;
      case 'assign_workpack':
      case 'reassign_workpack':
        setAssignModalOpened(true);
        return;
      default:
        break;
    }

    const nextStatus = action.to;
    if (nextStatus) {
      await updateEventStatus(nextStatus);
    }

    await createWorkflow(action, workflowComment);

    if (action.closeForm) {
      // Close event
      setActiveEvent();
      // Navigate one url segment back
      const urlSegments = location.pathname.split('/');
      const dst = urlSegments.slice(0, urlSegments.length - 1).join('/');
      navigate(dst);
    }
  };

  const statusBg = event.statusWorkOrder?.displayColor ? `bg-${event.statusWorkOrder?.displayColor}-200` : 'bg-gray-200';
  const statusFg = event.statusWorkOrder?.displayColor ? `text-${event.statusWorkOrder?.displayColor}-700` : 'text-gray-700';

  const availableActions = useMemo(() => getEventAvailableActions(event.status), [event.status]);
  const backAction = availableActions.backward;
  const forwardAction = availableActions.forward;

  useEffect(() => {
    // Workpack regenerated?
    if (localStorage.getItem('workpackGenerated')) {
      AppNotifications.success(t('message.success.workpackGenerated'));
      localStorage.removeItem('workpackGenerated');
    }
  }, []);

  return (
    <DetailsPageFooter
      actionButtons={
        <>
          {/* Action buttons */}
          {backAction && <RadButton className='bg-destructive hover:bg-destructive/90 gap-2' disabled={readonly} onClick={() => onWorkpackAction(backAction)}>{backAction.icon ? <backAction.icon/> : <LuXCircle/>} {t(`label.eventActions.buttons.${backAction.key}`)}</RadButton>}
          {forwardAction && <RadButton ref={workflowBtnContainerRef} className='bg-primary gap-2' disabled={readonly || !canValidate} onClick={() => onWorkpackAction(forwardAction)}>{forwardAction.icon ? <forwardAction.icon/> : <LuCheckCircle/>} {t(`label.eventActions.buttons.${forwardAction.key}`)}</RadButton>}
          { // Secondary actions
            availableActions.secondary.length > 0 && (
              <ActionThreeDotsMenu>
                {
                  availableActions.secondary.map(action => (
                    <RadDropdownMenuItem key={action.key} className='gap-2' onClick={() => onWorkpackAction(action)}>
                      {
                        action.icon
                          ? <action.icon/>
                          : (
                            action.dir === WorkflowActionDirection.forward
                              ? <LuCheckCircle/>
                              : <LuXCircle/>
                          )
                      }
                      {t(`label.eventActions.buttons.${action.key}`)}
                    </RadDropdownMenuItem>
                  ))
                }
              </ActionThreeDotsMenu>
            )
          }

          {/* Generate error tooltip */}
          {workflowBtnContainerRef.current && forwardAction?.key === 'generation_of_workpack' && (!!generateButtonErrorToolTipMessage) && (
            <AppTooltip
              position='top'
              target={workflowBtnContainerRef.current}
            >{generateButtonErrorToolTipMessage}</AppTooltip>
          )}
        </>
      }
    >
      <RadBadge variant='outline' className={`${statusBg} ${statusFg} py-1 font-normal`}>{event.statusWorkOrder?.description ?? '-'}</RadBadge>
      <Separator/>
      <span className='text-foreground'>
        ID: #{event.id}
      </span>
      {event.inspectorId
        && (event.status === EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET
          || event.status === EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION)
        && (
          <>
            <Separator/>
            <span className='text-foreground'>
              {t('label.assignedTo').toUpperCase()} <b>{event.inspectorId}</b>
            </span>
          </>
        )}
    </DetailsPageFooter>
  );
}
