import PageContent from '@app/components/Layout/PageContent';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {QueryResult, useLazyQuery} from '@apollo/client';
import {GetPlanDetailByIdQuery, GetPlanDetailByNumberQuery, Plan} from '@app/graphql/__types__/graphql';
import {SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER} from '@app/graphql/requests';
import LeftSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/LeftSide';
import RightSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/RightSide';
import {useLayoutStore} from '@app/stores/layout';
import {useEffect} from 'react';
import usePlanStore from '@app/stores/plan';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import Footer from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Footer';
import useItemStore from '@app/stores/item';
import PlanWorkflowTableModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Workflow';
import {EAdditionalAction, ESCHEDULING_PLAN_STATUS} from '@app/utils/enums';
import {TAdditionalActions} from '@app/types/app';
import {MdOutlineLocalPrintshop} from 'react-icons/md';
import {addTokenToUrl, downloadFileFromUrl, getCurentDateTimeFileName} from '@app/utils/functions';
import DeletePlanModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/DeletePlanModal';
import {LuGitFork, LuTrash2} from 'react-icons/lu';
import ItemModal from '@app/components/Modal/Scheduling/Item';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import {useHolisAuth} from '@holis/auth-client-react';
import AppNotifications from '@app/services/notification';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';

type routeParams = {
  number: string;
};

export default function SchedulingInspectionPlanDetailsPage() {
  const {number: planNumber} = useParams<routeParams>();
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const {editPlan, setFetchPlan, updatePlan, items, events, flocs, setActivePlan, workflowModalDisplayed, hasError, changeDeletePlanModalDisplay, changeWorkflowModalDisplay, deletePlanModalOpen} = usePlanStore();
  const {activeItem} = useItemStore();
  const {getAccessToken} = useHolisAuth();
  const [getPlanDetail, {loading}] = useLazyQuery<GetPlanDetailByNumberQuery>(SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER, {
    variables: {number: planNumber},
    fetchPolicy: 'no-cache',
  });

  const getAndSetPlanDetail = () => getPlanDetail().then((queryResult: QueryResult<GetPlanDetailByIdQuery>) => {
    const newPlan = {...(queryResult.data?.plan ?? {})} as Partial<Plan>;
    updatePlan(newPlan);

    return newPlan;
  });

  const showWorkflowModal = () => {
    changeWorkflowModalDisplay(true);
  };

  const printPlanReport = async () => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.reportBaseUrl}/plan/${editPlan!.id}`, getAccessToken);
    // openFileFromUrlInNewTab(reportUrl, `Plan-Report-${editPlan!.plan}-${getCurentDateTimeFileName()}.pdf`, {
    //   onEnd() {
    //     stopLoading();
    //   },
    //   onFail(error) {
    //     console.log(error);
    //     stopLoading();
    //     AppNotifications.error(t('message.error.default.title'));
    //   },
    //   getAccessToken,
    // });
    downloadFileFromUrl(reportUrl, `Plan-Report-${editPlan!.plan}-${getCurentDateTimeFileName()}.pdf`, {
      onEnd() {
        stopLoading();
      },
      onFail(error) {
        console.log(error);
        stopLoading();
        AppNotifications.error(t('message.error.default.title'));
      },
      getAccessToken,
    });
  };

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loading]);

  useEffect(() => {
    setFetchPlan(getAndSetPlanDetail);
    getAndSetPlanDetail().then(setActivePlan);
  }, []);

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={showWorkflowModal}><LuGitFork/> {t('label.workflow')}</RadDropdownMenuItem>,
    [EAdditionalAction.PRINT_PLAN]: <RadDropdownMenuItem className='gap-2' onClick={printPlanReport}><MdOutlineLocalPrintshop className='text-sm'/> {t('label.printPlan')}</RadDropdownMenuItem>,
    ...(editPlan?.status !== ESCHEDULING_PLAN_STATUS.CANCEL ? {[EAdditionalAction.DELETE]: <RadDropdownMenuItem className='gap-2 item-destructive' onClick={() => changeDeletePlanModalDisplay(true)}><LuTrash2/> {t('label.deletePlan')}</RadDropdownMenuItem>} : {}),
  };

  const itemsSummary: TSummaryItem[] = [
    {label: 'object', value: flocs?.length ?? 0},
    {label: 'item', value: items?.length ?? 0},
    {label: 'event', value: events?.length ?? 0},
  ];

  return (
    <>
      <PageContent
        header={
          <DetailsPageTitleBar
            title={`${t('label.inspectionPlan')} ${editPlan?.plan}`}
            actionButtons={{hasError, additionalActions}}
          >
            <ItemsSummary items={itemsSummary}/>
          </DetailsPageTitleBar>
        }
        footer={editPlan ? <Footer plan={editPlan}/> : undefined}
      >
        {
          editPlan
        && <MultipleResizablePanels
          panelsDefaultSize={[50, 50]}
          panelsMinSize={[27, 44]}
          autoSaveId='plan-detail'
          resizerClassName='left-0 panelResizer'
        >
          <LeftSide plan={editPlan}/>
          <RightSide plan={editPlan}/>
        </MultipleResizablePanels>
        // <RadResizablePanelGroup className='flex' direction='horizontal' autoSaveId='resizable-panel-plan-details'>
        //   <RadResizablePanel defaultSize={50} minSize={27}>
        //     <LeftSide plan={editPlan}/>
        //   </RadResizablePanel>
        //   <RadResizableHandle/>
        //   <RadResizablePanel defaultSize={50} minSize={44}>
        //     <RightSide plan={editPlan}/>
        //   </RadResizablePanel>
        // </RadResizablePanelGroup>
        }

        <DeletePlanModal open={deletePlanModalOpen}/>
      </PageContent>

      {activeItem && <ItemModal open item={activeItem}/>}
      {workflowModalDisplayed && <PlanWorkflowTableModal open plan={editPlan!}/>}
    </>
  );
}
