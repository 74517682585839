import {IconType} from 'react-icons/lib';
import {EWORKORDER_EVENT_STATUS} from '../enums';
import {LuRocket} from 'react-icons/lu';

export enum WorkflowActionDirection {
  forward = 'FORWARD',
  backward = 'BACKWARD',
  secondary = 'SECONDARY',
}
export enum WorkflowConfirmType {
  confirm = 'CONFIRM',
  comment = 'COMMENT',
  reason = 'REASON',
}
export const EVENT_WORKFLOW = [
  {
    key: 'import_of_event' as const,
    dir: WorkflowActionDirection.forward,
    from: null,
    to: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
  },
  {
    key: 'release_of_event' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PEND_WAITING_RELEASE,
    to: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
  },
  {
    key: 'generation_of_workpack' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    icon: LuRocket,
  },
  {
    key: 'review_of_workpack' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_workpack' as const,
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    confirm: WorkflowConfirmType.reason,
    closeForm: true,
  },
  {
    key: 'approve_workpack' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'assign_workpack' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    closeForm: true,
    confirm: WorkflowConfirmType.comment,
  },
  {
    key: 'back_to_preparation' as const,
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    closeForm: true,
  },
  {
    key: 'reassign_workpack' as const,
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    confirm: WorkflowConfirmType.comment,
  },
  {
    key: 'report_workpack' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    closeForm: true,
  },
  {
    key: 'back_to_execution' as const,
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'preparation_of_report' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_report_revi' as const,
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    confirm: WorkflowConfirmType.reason,
  },
  {
    key: 'review_of_report' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_report_appr' as const,
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    confirm: WorkflowConfirmType.reason,
  },
  {
    key: 'approve_report' as const,
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.COMPLETED,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
];
export type EventWorkflowActionKey = typeof EVENT_WORKFLOW[number]['key'];

export const getNextEventStatus = (currentStatus?: EWORKORDER_EVENT_STATUS | null | string, choice?: number): EWORKORDER_EVENT_STATUS | null => {
  switch (currentStatus) {
    case EWORKORDER_EVENT_STATUS.PEND_WAITING_RELEASE:
      return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    // case EWORKORDER_EVENT_STATUS.PREP_PENDING:
    //   return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.PREP_PROCESSING:
      return EWORKORDER_EVENT_STATUS.PREP_REVIEWING;
    case EWORKORDER_EVENT_STATUS.PREP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.PREP_APPROVING;
    case EWORKORDER_EVENT_STATUS.PREP_APPROVING:
      return EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN;
    case EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN:
      return EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION;
    case EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION:
      // Export to tablet
      if (choice === 1) {
        return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
      }

      return EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION;
    case EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET:
      // Inspection not completed
      if (choice === 1) {
        return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
      }

      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION:
      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.REP_PROCESSING:
      return EWORKORDER_EVENT_STATUS.REP_REVIEWING;
    case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.REP_APPROVING;
    case EWORKORDER_EVENT_STATUS.REP_APPROVING:
      return EWORKORDER_EVENT_STATUS.COMPLETED;
    default:
      break;
  }

  return null;
};

export const getPrevEventStatus = (currentStatus?: EWORKORDER_EVENT_STATUS | null | string): EWORKORDER_EVENT_STATUS | null => {
  switch (currentStatus) {
    case EWORKORDER_EVENT_STATUS.PREP_REVIEWING:
    case EWORKORDER_EVENT_STATUS.PREP_APPROVING:
      return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION:
    case EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN;
    case EWORKORDER_EVENT_STATUS.REP_APPROVING:
    case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
    default:
      break;
  }

  return null;
};

export type EventWorkflowAction = {
  key: EventWorkflowActionKey;
  dir: WorkflowActionDirection;
  from: EWORKORDER_EVENT_STATUS | null;
  to: EWORKORDER_EVENT_STATUS;
  confirm?: WorkflowConfirmType;
  closeForm: boolean;
  icon?: IconType;
};

export const getEventAvailableActions = (currentStatus?: EWORKORDER_EVENT_STATUS | null| string) : {
  forward: EventWorkflowAction | undefined;
  backward: EventWorkflowAction | undefined;
  secondary: EventWorkflowAction[];
} => {
  const actions
    = EVENT_WORKFLOW
      .filter(action => action.from === currentStatus)
      .map(action => ({...action, key: action.key as EventWorkflowActionKey, closeForm: !!action.closeForm}));

  return {
    forward: actions.find(action => action.dir === WorkflowActionDirection.forward),
    backward: actions.find(action => action.dir === WorkflowActionDirection.backward),
    secondary: actions.filter(action => action.dir === WorkflowActionDirection.secondary),
  };
};
