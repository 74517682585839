
export class OptimusClientConfig {
  static current: OptimusClientConfig = new OptimusClientConfig();
  apiBaseUrl = '/api';
  apiRestUrl = '/rest';
  fileBaseUrl = '/files';
  pictureBaseUrl = '/pictures';
  fileUploadUrl = '/import';
  fileDownloadUrl = '/export';
  reportBaseUrl = '/app/report';
  authServerUrl = 'https://total.auth.staging.holis.cloud';
  authClientId = '00000000-0000-0000-0000-000000000000';
  projectName = '';
  assetName = '';
}

export class OptimusClientConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'OptimusClientConfigError';
  }
}

export const fetchClientConfig = async () => {
  console.log(import.meta.env.BASE_URL + 'client-config.json');
  const r = await fetch(import.meta.env.BASE_URL + 'client-config.json');
  if (!r.ok) {
    throw new OptimusClientConfigError('Failed to fetch client config: client-config.json');
  }

  try {
    const override = await r.json();
    OptimusClientConfig.current = Object.assign(new OptimusClientConfig(), override);
  } catch (e) {
    throw new OptimusClientConfigError('Failed to parse client config: ' + (e as Error).message);
  }

  return OptimusClientConfig.current;
};
