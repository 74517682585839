import {RadButton, RadDialogClose} from '@holis/react-ui/rad';

type TFooter = {
	readonly onSaveClick: () => void;
};

export default function Footer({onSaveClick}: TFooter) {
  return (
    <>
      <RadDialogClose asChild>
        <RadButton variant='outline'>
          Cancel
        </RadButton>
      </RadDialogClose>

      <RadButton onClick={onSaveClick}>
        Create
      </RadButton>
    </>
  );
}
