import React from 'react';
import {Plan} from '@app/graphql/__types__/graphql';
import FlocBlock from './FlocBlock';
import SearchBar from '@app/components/Common/SearchBar';

type TObjectList = Readonly<{
  plan: Partial<Plan>,
}>;

export default function ObjectList({plan}: TObjectList) {
  const [filterString, setSearchInput] = React.useState<string>('');
  return (
    <div className='flex flex-col gap-2 pl-2 py-2 w-full'>
      <SearchBar value={filterString} onChange={e => setSearchInput(e.target?.value)}/>
      <FlocBlock plan={plan} filterString={filterString}/>
    </div>
  );
}
