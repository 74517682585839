import UploadDownloadService from '@app/services/api/UploadDownloadService';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  uploadDownloadService?: UploadDownloadService;
}

type Actions = {
  setUploadDownloadService: (uploadDownloadService: UploadDownloadService) => void;
}

export const useDataStore = create<State & Actions>()(
  immer(set => ({
    setUploadDownloadService(uploadDownloadService: UploadDownloadService) {
      set({uploadDownloadService});
    },
  })),
);
