import {gql} from '@apollo/client';

export const DOCUMENTATION_DOCUMENTS_COMMON_FIELDS = gql`
  fragment DocumentCommonFields on Document {
    id
    document
    description
    docName
    sector {
      id
      sector
      site {
        id
        site
      }
      description
    }
    discipline {
      id
      code
      description
    }
    type {
      id
      code
      description
    }
    phase {
      id
      code
      description
    }
    catalog {
      id
      code
      description
    }
    stage {
      id
      code
      description
    }
    revision
    originator {
      id
      code
      description
    }
    catalogId
    disciplineId
    originatorId
    phaseId
    sectorId
    stageId
    typeId
    flagStatus
  }

`;

export const DOCUMENTATION_DOCUMENTS_COUNTER_FIELDS = gql`
  fragment DocumentCounterFields on Document {
    _count {
      flocDocs
    }
  }
`;
