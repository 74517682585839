import {Notification} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {TAdditionalActions} from '@app/types/app';
import {EAdditionalAction} from '@app/utils/enums';
import WorkflowIcon from '@assets/images/icons/workflow.svg';
import {MdOutlineLocalPrintshop} from 'react-icons/md';
import useNotificationStore from '@app/stores/notification';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {LuTrash2} from 'react-icons/lu';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {addTokenToUrl, downloadFileFromUrl, getCurentDateTimeFileName} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {OptimusClientConfig} from '@app/utils/clientConfig';

type THeader = Readonly<{
    notification: Partial<Notification>
}>

export default function Header({notification}: THeader) {
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const {getAccessToken} = useHolisAuth();
  const {changeDeleteNotificationModalDisplay, activeNotification, changeWorkflowModalDisplay, notificationDamages, attachments} = useNotificationStore();
  const itemsSummary: TSummaryItem[] = [
    {
      label: 'damage',
      value: notificationDamages?.length ?? activeNotification?._count?.notificationDamages ?? 0,
    },
    {
      label: 'attachment',
      value: attachments?.length ?? activeNotification?._count?.attachments ?? 0,
    },
  ];

  const handleShowWorkflow = () => {
    changeWorkflowModalDisplay(true);
  };

  const handlePrint = async () => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.reportBaseUrl}/notification/${notification.id}`, getAccessToken);
    // openFileFromUrlInNewTab(reportUrl, `IAN-Report-${notification!.notif}-${getCurentDateTimeFileName()}.pdf`, {
    //   onEnd() {
    //     stopLoading();
    //   },
    //   onFail(error) {
    //     console.log(error);
    //     stopLoading();
    //     AppNotifications.error(t('message.error.default.title'));
    //   },
    //   getAccessToken,
    // });
    downloadFileFromUrl(reportUrl, `IAN-Report-${notification!.notif}-${getCurentDateTimeFileName()}.pdf`, {
      onEnd() {
        stopLoading();
      },
      onFail(error) {
        console.log(error);
        stopLoading();
        AppNotifications.error(t('message.error.default.title'));
      },
      getAccessToken,
    });
  };

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={handleShowWorkflow}><WorkflowIcon/> {t('label.workflow')}</RadDropdownMenuItem>,
    [EAdditionalAction.PRINT_IAN]: <RadDropdownMenuItem className='gap-2' onClick={handlePrint}><MdOutlineLocalPrintshop className='text-sm'/> {t('label.printNotification')}</RadDropdownMenuItem>,
    [EAdditionalAction.DELETE]: <RadDropdownMenuItem className='gap-2 item-destructive' onClick={() => changeDeleteNotificationModalDisplay(true)}><LuTrash2/> {t('label.deleteNotification')}</RadDropdownMenuItem>,
  };

  return (
    <DetailsPageTitleBar
      title={`${activeNotification?.notif}`}
      subtitle={`${activeNotification?.functionalLocation?.floc}`}
      actionButtons={{
        additionalActions,
      }}
    >
      <ItemsSummary items={itemsSummary}/>
    </DetailsPageTitleBar>
    // <div className='flex w-full text-gray-700 text-sm uppercase items-center gap-2 pr-2'>
    //   {status === ENOTIFICATION_NOTIF_STATUS.INIT && activeNotification?.id === OBJ_NEW_ID && <ElevatedButton className='bg-teal-500 disabled:bg-opacity-70 uppercase w-max font-poppins text-white' disabled={hasError()} onClick={generateIan}><CustomIcon name='edit-check'/> {t('label.generateIan')}</ElevatedButton>}
    //   {status === ENOTIFICATION_NOTIF_STATUS.WAPP && <ElevatedButton className='bg-teal-500 disabled:bg-opacity-70 uppercase w-max font-poppins text-white' onClick={approveIan}><FaRegCheckCircle size={14}/> {t('label.approve')}</ElevatedButton>}
    //   {notification.id !== OBJ_NEW_ID && <ActionButtons
    //     dropdownTransitionClassName='top-[30px]'
    //     additionalActions={additionalActions}
    //   />}

  // </div>
  );
}
