import Astro from '@assets/images/Astro_Checkis.svg?url';

import {
  RadAvatar,
  RadAvatarImage,
  RadDropdownMenu,
  RadDropdownMenuContent,
  RadDropdownMenuItem,
  RadDropdownMenuLabel,
  RadDropdownMenuSeparator,
  RadDropdownMenuTrigger,
} from '@holis/react-ui/rad';

import {useHolisAuth} from '@holis/auth-client-react';
import {LuComputer, LuLogOut, LuMoon, LuSun} from 'react-icons/lu';

import {useTheme} from '@contexts/ThemeContext';

export default function UserDropdown() {
  const {setTheme} = useTheme();
  const {user, logout} = useHolisAuth();

  return (
    <RadDropdownMenu>
      <RadDropdownMenuTrigger className='rounded-full'>
        <RadAvatar className='h-8 w-8'>
          <RadAvatarImage src={Astro}/>
        </RadAvatar>
      </RadDropdownMenuTrigger>

      <RadDropdownMenuContent align='end' className='w-48 border-border'>
        <RadDropdownMenuLabel className='text-muted-foreground'>
          {user?.username}
        </RadDropdownMenuLabel>

        <RadDropdownMenuSeparator/>

        <RadDropdownMenuLabel>
          Themes
        </RadDropdownMenuLabel>

        <RadDropdownMenuItem className='gap-2 cursor-pointer' onClick={() => setTheme('light')}>
          <LuSun/> Light
        </RadDropdownMenuItem>

        <RadDropdownMenuItem className='gap-2 cursor-pointer' onClick={() => setTheme('dark')}>
          <LuMoon/> Dark
        </RadDropdownMenuItem>

        <RadDropdownMenuItem className='gap-2 cursor-pointer' onClick={() => setTheme('system')}>
          <LuComputer/> System
        </RadDropdownMenuItem>

        <RadDropdownMenuSeparator/>

        <RadDropdownMenuLabel>
          Action
        </RadDropdownMenuLabel>

        <RadDropdownMenuItem className='gap-2 text-red-400 cursor-pointer' onClick={logout}>
          <LuLogOut/>
          Logout
        </RadDropdownMenuItem>
      </RadDropdownMenuContent>
    </RadDropdownMenu>
  );
}
