import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import React from 'react';
import {TransformWrapper, TransformComponent, ReactZoomPanPinchRef, ReactZoomPanPinchContentRef, ReactZoomPanPinchProps} from 'react-zoom-pan-pinch';
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader';
import {twMerge} from 'tailwind-merge';

export type TLayoutViewerParams = Readonly<{
    zoomContentRef: ReactZoomPanPinchContentRef;
    zoomLevel: number;
    onChangeZoomLevel: (zoomLevel: number)=>void;
    transformComponentRef?: ReactZoomPanPinchRef | null
}>

export type TZoomProps = ReactZoomPanPinchProps & {defaultCursor?: string};

export type TLayoutViewer = Readonly<{
    zoomProps?: TZoomProps;
    header?: React.ReactNode | ((params: TLayoutViewerParams)=>React.ReactNode);
    footer?: React.ReactNode | ((params: TLayoutViewerParams)=>React.ReactNode);
    children?: React.ReactNode | ((params: TLayoutViewerParams)=> React.ReactNode);
    isLoading?: boolean;
    viewerRef?: React.RefObject<HTMLDivElement>;
    className?: string;
    contentClassName?: string;
    onDoubleClick?: (e?: React.MouseEvent<HTMLElement>, transformComponentRef?: ReactZoomPanPinchRef) => void;
}>

const ViewerContainer = styled.div<{ $zoomLevel?: number}>`
    /* canvas {
        width: 100% !important; */
        /* min-width: 100% !important; */
        /* width: ${props => (props.$zoomLevel && props.$zoomLevel <= 1 ? (100 * props.$zoomLevel) + '% !important' : 'auto')}; */
        /* height: auto !important;
    } */

`;

const ViewerHeader = styled.div`
    top: 0;
    z-index: 10;
`;

const ViewerFooter = styled.div`
    width: 100%;
`;

enum EViewerCursors {
    GRAB = 'cursor-grab',
    ZOOM_IN = 'cursor-zoom-in',
    ZOOM_OUT = 'cursor-zoom-out',
}

export default function LayoutViewer({children, footer, zoomProps, header, isLoading, viewerRef, className, onDoubleClick, contentClassName}: TLayoutViewer) {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [viewerCursor, setViewerCursor] = useState<string>(zoomProps?.defaultCursor ?? EViewerCursors.GRAB);

  useEffect(() => {
    setViewerCursor(zoomProps?.defaultCursor ?? EViewerCursors.GRAB);
  }, [zoomProps?.defaultCursor]);

  const onChangeZoomLevel = (newZoomLevel: number) => {
    setZoomLevel(newZoomLevel);
  };

  const handleTransformed = (_ref: ReactZoomPanPinchRef, state: {
        scale: number;
        positionX: number;
        positionY: number;
  }) => {
    if (state.scale === 1) {
      setViewerCursor(EViewerCursors.GRAB);
    }
  };

  const handleZoomStop = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    const currentScale = ref.state.scale;
    const {previousScale} = ref.state;
    if (currentScale === 1) {
      setViewerCursor(EViewerCursors.GRAB);
    } else if (currentScale > previousScale) {
      setViewerCursor(EViewerCursors.ZOOM_IN);
    } else {
      setViewerCursor(EViewerCursors.ZOOM_OUT);
    }

    onChangeZoomLevel(currentScale);
    zoomProps?.onZoomStop?.(ref, event);
  };

  const handleDblClick = (e: React.MouseEvent<HTMLElement>, transformComponentRef: ReactZoomPanPinchRef) => {
    e.preventDefault();
    e.stopPropagation();
    setZoomLevel(1);
    transformComponentRef.resetTransform();
    onDoubleClick?.(e, transformComponentRef);
  };

  const handlePanningStart = (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => {
    setViewerCursor(EViewerCursors.GRAB);
    zoomProps?.onPanningStart?.(ref, event);
  };

  return (
    <ViewerContainer ref={viewerRef} $zoomLevel={zoomLevel} className={twMerge('relative flex-col justify-center m-auto items-center flex w-full h-full', className)}>
      <SpinnerLoader className='absolute' isLoading={isLoading}/>
      <TransformWrapper
        ref={transformComponentRef}
        smooth
        disablePadding
        limitToBounds
        initialScale={1}
        doubleClick={{
          mode: 'reset',
        }}
        // wheel={{
        //   smoothStep: 0.008,
        // }}
        minScale={1}
        maxScale={16}
        panning={{
          velocityDisabled: true,
        }}
        {...zoomProps}
        onTransformed={handleTransformed}
        onPanningStart={handlePanningStart}
        onZoomStop={handleZoomStop}
      >
        {(zoomContentRef: ReactZoomPanPinchContentRef) => (
          <>
            {header
                      && <ViewerHeader className='absolute w-full flex flex-col items-center'>
                        {typeof header === 'function' ? header({zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current}) : header}
                      </ViewerHeader>}
            <TransformComponent
              wrapperStyle={{width: '100%', height: '100%'}}
              contentStyle={{width: '100%', height: '100%'}}
            >
              <div className={twMerge(`${viewerCursor} h-full w-full`, contentClassName)} onDoubleClick={e => handleDblClick(e, transformComponentRef.current!)}>
                {typeof children === 'function' ? children({zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current}) : children}
              </div>
            </TransformComponent>
            {footer
                      && <ViewerFooter className='absolute bottom-2 flex flex-col items-center'>
                        {typeof footer === 'function' ? footer({zoomContentRef, zoomLevel, onChangeZoomLevel, transformComponentRef: transformComponentRef.current}) : footer}
                      </ViewerFooter>}
          </>
        )}
      </TransformWrapper>
    </ViewerContainer>
  );
}
