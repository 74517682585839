import {useLazyQuery} from '@apollo/client';
import DocumentCard from '@app/components/Common/Block/Document/DocumentBlock/DocumentCard';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {Document, FunctionalLocation, GetDocumentsByFlocIdsQuery} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS} from '@app/graphql/requests';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {EAppFlagStatus} from '@app/utils/enums';
import {searchArray} from '@app/utils/functions';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {LuFile} from 'react-icons/lu';

type TCmlList = Readonly<{
  floc: Partial<FunctionalLocation>;
}>;

/** Floc document list */
export default function DocumentList({floc}: TCmlList) {
  const [filteredDocuments, setFilteredDocuments] = useState<Partial<Document>[]>();
  const [filterString, setSearchInput] = useState<string>('');

  const {
    documents,
    setDocuments,
    documentInactiveShown,
  } = useFlocStore();

  const [getDocumentListApi, {called, refetch}] = useLazyQuery<GetDocumentsByFlocIdsQuery>(
    DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS,
    {
      variables: {
        flocIds: [floc.id],
        orderBy: [
          {
            id: 'desc',
          },
        ],
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    fetchDocuments();
  }, [floc.id]);

  useEffect(() => {
    setFilteredDocuments(
      searchArray(
        documents ?? [],
        filterString,
        undefined,
        ['__typename'],
      ).filter((document: Partial<Document>) => (
        document.flagStatus === EAppFlagStatus.ACTIVE.toString() || documentInactiveShown
      )),
    );
  }, [documents, filterString, documentInactiveShown]);

  const fetchDocuments = () => {
    (called ? refetch : getDocumentListApi)()
      .then(queryResults => setDocuments(queryResults.data?.documents as Document[] ?? []));
  };

  return (
    <>
      <FormGroupHeader
        menuItems={[
          // (
          //   <RadDropdownMenuItem key='manage-object'>
          //     <LuList className='mr-2'/>

          //     {t('label.displayList')}
          //   </RadDropdownMenuItem>
          // ),
        ]}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }
      >
        <div className='flex items-center'>
          <LuFile size={20} className='mr-2'/>

          {t('label.documentation')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col gap-1 mt-3'>
        {filteredDocuments?.map(document => (
          <DocumentCard
            key={document.id}
            hasContextMenu
            doc={document}
          />
        ))}
      </div>
    </>
  );
}
