import {gql} from '@apollo/client';

export const ASSET_SITES_GET_COUNTER = gql`
query GetAssetSitesCounter {
  aggregateAssetSite {
    _count {
      id
    }
  }
}
`;
