import {gql} from '@apollo/client';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS, DOCUMENTATION_DOCUMENTS_FLOCS_COMMON_FIELDS} from '../fragments';

export const DOCUMENTATION_DOCUMENTS_FLOCS_GET_BY_DOCUMENT_ID = gql`
${DOCUMENTATION_DOCUMENTS_FLOCS_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetDocumentFlocsByDocumentId($docId: Int!) {
  flocDocs(where: {docId: {equals: $docId}}) {
    ...DocumentFlocCommonFields
    functionalLocation {
      ...FlocAutocompleteCommonFields
    }
  }
}`;

export const DOCUMENTATION_DOCUMENTS_FLOCS_CREATE_MANY = gql`
mutation CreateDocFlocs($data: [FlocDocCreateManyInput!]!) {
  createManyFlocDoc(data: $data) {
    count
  }
}`;

export const DOCUMENTATION_DOCUMENTS_FLOCS_DELETE_BY_DOC_ID_AND_FLOC_IDS = gql`
mutation DeleteDocFlocsByDocIdAndFlocIds($docId: Int!, $flocIds: [Int!]) {
  deleteManyFlocDoc(where: {docId: {equals: $docId}, flocId: {in: $flocIds}}) {
    count
  }
}`;

export const DOCUMENTATION_DOCUMENTS_FLOCS_DELETE = gql`
mutation DeleteOneFlocDoc($docId: Int!, $flocId: Int!) {
  deleteOneFlocDoc(where: {flocId_docId: {docId: $docId, flocId: $flocId}}) {
    docId,
    flocId,
  }
}
`;

export const DOCUMENTATION_DOCUMENTS_FLOCS_GET_COUNTER = gql`
query GetDocumentationDocumentFlocsCounter {
  aggregateFlocDoc {
    _count {
      _all
    }
  }
}
`;

