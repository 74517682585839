import {gql} from '@apollo/client/core';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS, NOTIFICATIONS_COMMON_FIELDS, NOTIFICATIONS_DETAIL_FIELDS, NOTIFICATION_REF_TYPES_COMMON_FIELDS} from '../fragments';

export const NOTIFICATIONS_GET_ALL = gql`
${NOTIFICATIONS_DETAIL_FIELDS}
query GetAllNotifications($orderBy: [NotificationOrderByWithRelationInput!]) {
  notifications (orderBy: $orderBy) {
    ...NotificationDetailFields
  }
}`;

export const NOTIFICATIONS_GET_BY_EVT_ID = gql`
${NOTIFICATIONS_COMMON_FIELDS}
${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetNotificationsByEvtId($evtId: Int!) {
  notifications (where: {wrkoId: {equals: $evtId}}, orderBy:  [{creationDate: {sort: asc}}]) {
    ...NotificationCommonFields
    notifType{
      ...RefNotifTypeCommonFields
    }
    functionalLocation{
      ...FlocAutocompleteCommonFields
    }
  }
}`;

export const NOTIFICATIONS_GET_BY_DMG_ID = gql`
${NOTIFICATIONS_COMMON_FIELDS}
${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetNotificationsByDmgId($dmgId: Int!) {
  notifications (where: {notificationDamages: {some: {dmgeId: {equals: $dmgId}}}}, orderBy:  [{creationDate: {sort: asc}}]) {
    ...NotificationCommonFields
    notifType{
      ...RefNotifTypeCommonFields
    }
    functionalLocation{
      ...FlocAutocompleteCommonFields
    }
  }
}`;

export const NOTIFICATIONS_GET_BY_FLOC_IDS = gql`
${NOTIFICATIONS_COMMON_FIELDS}
${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
query GetNotificationsByFlocIds($flocIds: [Int!], $orderBy: [NotificationOrderByWithRelationInput!]) {
    notifications(where: {flocId: {in: $flocIds}}, orderBy: $orderBy){
      ...NotificationCommonFields
      notifType{
        ...RefNotifTypeCommonFields
      }
      functionalLocation{
        ...FlocAutocompleteCommonFields
      }
    }
}`;

export const NOTIFICATIONS_CREATE = gql`
${NOTIFICATIONS_DETAIL_FIELDS}
mutation createNotification($data: NotificationUncheckedCreateInput!) {
  createOneNotification(data: $data) {
    ...NotificationDetailFields
  }
}`;

export const NOTIFICATIONS_UPDATE_BY_ID = gql`
${NOTIFICATIONS_DETAIL_FIELDS}
mutation UpdateNotificationById($id: Int!, $data: NotificationUncheckedUpdateInput!) {
  updateOneNotification(data: $data, where: {id: $id}) {
    ...NotificationDetailFields
  }
}`;

export const NOTIFICATIONS_GET_DETAIL_BY_ID = gql`
${NOTIFICATIONS_DETAIL_FIELDS}
query GetNotificationDetailById($id: Int!) {
  notification(where: {id: $id}) {
    ...NotificationDetailFields
  }
}`;

export const NOTIFICATIONS_GET_DETAIL_BY_NUMBER = gql`
${NOTIFICATIONS_DETAIL_FIELDS}
query GetNotificationDetailByNumber($number: String!) {
  notification(where: {notif: $number}) {
    ...NotificationDetailFields
  }
}`;

export const NOTIFICATIONS_DELETE_BY_ID = gql`
mutation DeleteNotificationById($id: Int!) {
  deleteOneNotification(where: {id: $id}) {
    id
  }
}`;

export const NOTIFICATIONS_GET_COUNTER = gql`
query GetNotificationsCounter {
  aggregateNotification {
    _count {
      id
    }
  }
}
`;
